<template>
  <!-- 登录 -->
  <div class="container-login" v-if="isLogin==0" onselectstart="return false">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth" :style="{backgroundImage: 'url(' + systemSet.img + ')'}">
        <div class="row w-100">
          <div class="col-lg-4 mx-auto" style="position: relative;z-index: 999;">
            <div class="auth-form-light text-left p-5">
              <div class="brand-logo">
                <!-- <div style="width:150px;height:40px;background-size: contain;background-repeat: no-repeat;background-position: center" :style="{backgroundImage: 'url('+ systemSet.logo +')'}"></div> -->
                <img :src="systemSet.logo2">
              </div>
              <h4>欢迎！您正在使用青少年门诊系统</h4>
              <h6 class="font-weight-light" v-if="forget==0">请使用最新版谷歌浏览器或360浏览器登录账号.</h6>
              <h6 class="font-weight-light" v-else>请输入账号手机号，提交后将发送密码至手机</h6>

              <form class="pt-3" v-if="forget==0">
                <div class="form-group">
                  <el-input v-model="userName" placeholder="请输入账号" clearable />
                </div>
                <div class="form-group">
                  <el-input v-model="userPassword" placeholder="请输入密码" type="password" show-password/>
                </div>
                <div v-if="userName && userPassword" class="verify">
                  <div class="left">
                    <p>{{verify1}}</p>
                    <span>+</span>
                    <p>{{verify2}}</p>
                    <span>=</span>
                    <input type="" name="" v-model="verifyOk" placeholder="？">
                  </div>
                  <div class="right">
                    <a href="javascript:;" @click="verifyNews"><span class="mdi mdi-replay"></span></a>
                  </div>
                </div>
                <!-- <div class="code code1" v-if="userName && userPassword && codeShow==1">
                  <div class="b" style="background:#8bc34a!important"></div>
                  <span v-wahaha class="mdi mdi-chevron-double-right yzm" data-flagss="0"></span>
                </div>
                <div class="code code2" v-if="userName && userPassword && codeShow==2">
                  <div class="b" style="background:#8bc34a!important"></div>
                  <span class="mdi mdi-chevron-double-right yzm"  @mousedown="down" @touchstart.stop="down" @mousemove="move" @touchmove.stop="move" @mouseup="end" @touchend.stop="end" :style="{left:x+'px'}"></span>
                </div> -->
                <div class="mt-3">
                  <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn systemColor2" href="javascript:;" @click="toLogin()" style="height:auto">登录</a>
                </div>
                <div class="my-2 d-flex justify-content-between align-items-center">
                  <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input" value="true" v-model="isKeep">
                      记住账号密码
                      <i class="input-helper"></i>
                    </label>
                  </div>
                  <!-- <a href="javascript:;" class="auth-link text-black" @click="toForget(1)">忘记密码?</a> -->
                </div>
              </form>
              <form v-else>
                <div class="form-group">
                  <input type="password" class="form-control form-control-lg" v-model="userPassword" placeholder="请账号手机号">
                </div>
                <div class="mt-3">
                  <a class="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn systemColor2" href="javascript:;" @click="toForget(0)">提交</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- content-wrapper ends -->
    </div>
    <div class="wrapper systemColor2">
      <ul class="bg-bubbles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
  <!-- 公共模板 -->
  <div class="container-scroller" v-else>
    <!-- partial:partials/_navbar.html -->
    <nav class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <a class="navbar-brand brand-logo" href="/#/"><img :src="systemSet.logo" alt="logo"/></a>
        <a class="navbar-brand brand-logo-mini" href="/#/"><img src="./assets/images/logo-mini2.png" alt="logo" style="width:40px" /></a>
      </div>
      <div class="navbar-menu-wrapper d-flex align-items-stretch">
        <!-- <div class="search-field d-none d-md-block">
          <form class="d-flex align-items-center h-100" action="#">
            <div class="input-group">
              <div class="input-group-prepend bg-transparent">
                  <i class="input-group-text border-0 mdi mdi-magnify"></i>                
              </div>
              <input type="text" class="form-control bg-transparent border-0" placeholder="Search projects">
            </div>
          </form>
        </div> -->
        <div style="display:flex;align-items:center" class="pcNav">
          <span class="mdi mdi-format-list-bulleted" style="margin-right: 10px;"></span>
          <a href="/#/Index" style="font-size: 14px;color:#333;">首页</a>
          <span style="padding: 0px 10px;" v-show="Navtxt1!=''"> / </span>
          <a href="javascript:;" style="font-size: 14px;color:#333;" v-show="Navtxt1!=''">{{Navtxt1}}</a>
          <span style="padding: 0px 10px;" v-show="Navtxt2!=''"> / </span>
          <a href="javascript:;" style="font-size: 14px;color:#999;" v-show="Navtxt2!=''" class="ani" :class="isWait==1?'on':'close'">{{Navtxt2}}</a>
          <!-- <span style="padding: 0px 10px;" v-if="isNav"> / </span>
          <a href="javascript:;" style="font-size: 14px;color:#999;" v-if="isNav">{{Navtxt3}}</a> -->
        </div>
        <ul class="navbar-nav navbar-nav-right">
          <li class="nav-item nav-profile dropdown">
            <a class="nav-link dropdown-toggle" id="profileDropdown" href="javascript:;" data-toggle="dropdown" aria-expanded="false">
              <div class="nav-profile-img">
                <!-- <img :src="admin.img" alt="image"> -->
                <!-- <div style="width:32px;height:32px;background-size: cover;background-repeat: no-repeat;background-position: center" :style="{backgroundImage: 'url('+ systemSet.head +')'}"></div> -->
                <img :src="userInfo.hospital.head">
                <span class="availability-status online"></span>             
              </div>
              <div class="nav-profile-text">
                <p class="mb-1 text-black">{{userInfo.hospital.name}}</p>
              </div>
            </a>
            <div class="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown" v-if="isLogin!='all'">
              <a class="dropdown-item" href="/#/SetBasis">
                <i class="mdi mdi-settings mr-2 text-success"></i>
                系统设置
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="javascript:;"  @click="logout()">
                <i class="mdi mdi-logout mr-2 text-primary"></i>
                退出
              </a>
            </div>
          </li>
          <li class="nav-item d-none d-lg-block full-screen-link">
            <a class="nav-link" v-if="isScreen == 0" href="javascript:;">
              <i class="mdi mdi-fullscreen"  @click="requestFullscreen ()"></i>
            </a>
            <a class="nav-link" v-else href="javascript:;">
              <i class="mdi mdi-fullscreen-exit" @click="exitFullScreen()"></i>
            </a>
          </li>
          <li class="nav-item dropdown" v-if="isLogin!='all'">
            <a class="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="javascript:;" data-toggle="dropdown">
              <i class="mdi mdi-bell-outline"></i>
              <span class="count-symbol bg-danger"></span>
            </a>
            <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
              <h6 class="p-3 mb-0">系统消息</h6>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item" href="javascript:;" @click="toOrder()">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-success">
                    <i class="mdi mdi-calendar"></i>
                  </div>
                </div>
                <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 class="preview-subject font-weight-normal mb-1">未完成订单</h6>
                  <p class="text-gray ellipsis mb-0">
                    今天仍有 {{userInfo.order_count}} 单订单未完成
                  </p>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item" href="javascript:;" @click="toProduct()">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-warning">
                    <i class="mdi mdi-settings"></i>
                  </div>
                </div>
                <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 class="preview-subject font-weight-normal mb-1">库存警告</h6>
                  <p class="text-gray ellipsis mb-0">
                    <!-- 有 {{userInfo.goods_alarm}} 件常规、 {{userInfo.drug_alarm}} 件药监产品需要补货 -->
                    查看库存小于预警的产品
                  </p>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item preview-item" href="javascript:;">
                <div class="preview-thumbnail">
                  <div class="preview-icon bg-info">
                    <i class="mdi mdi-link-variant"></i>
                  </div>
                </div>
                <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 class="preview-subject font-weight-normal mb-1">账号使用</h6>
                  <p class="text-gray ellipsis mb-0">
                    账号还有 {{userInfo.expires_time}} 天使用期限
                  </p>
                </div>
              </a>
              <!-- <div class="dropdown-divider"></div>
              <h6 class="p-3 mb-0 text-center">See all notifications</h6> -->
            </div>
          </li>
          <li class="nav-item nav-logout d-none d-lg-block" @click="logout()">
            <a class="nav-link" href="javascript:;">
              <i class="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
        <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" @click="pmdNav()">
          <span class="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
    <!-- partial -->
    <div class="partial" v-if="isLogin=='all'">
      <div class="content-wrapper" style="margin-top: 70px">
        <router-view></router-view>
      </div>
    </div>
    <div class="container-fluid page-body-wrapper" v-else style="position:relative;padding-top: 0px;height:100%;min-height:100vh">
      <!-- partial:partials/_sidebar.html -->
      <nav class="sidebar sidebar-offcanvas systemColor2" id="sidebar" :class="pmdAct?'active':''" style="min-height: 100vh;z-index:1033;overflow-y: hidden">
        <div style="width:100%;padding:17px 20%;height:70px;">
          <a href="/#/Index" v-if="isLogin!='hospitals'">
            <img :src="systemSet.logo" style="width:100%">
          </a>
          <a href="javascript:;" v-else>
            <img :src="systemSet.logo" style="width:100%">
          </a>
        </div>
        <div style="overflow-y:auto;height:calc(100vh - 70px)">
          <ul class="nav nav1" v-if="isLogin!='hospitals' && lvNav==true && isLogin!='keepers' && isLogin!='buyers' && isLogin!='financials'">
            <li class="nav-item nav-profile">
              <a href="/#/Index" class="nav-link">
                <div class="nav-profile-image">
                  <!-- <div style="width:44px;height:44px;background-size: cover;background-repeat: no-repeat;background-position: center" :style="{backgroundImage: 'url('+ systemSet.head +')'}"></div> -->
                  <img :src="userInfo.hospital.head">
                  <span class="login-status online"></span>
                </div>
                <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">{{userInfo.admin.name}}</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospitals'">总管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keepers'">总店库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'buyers'">总店采购</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financials'">总店财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospital'">管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'supplier'">供货商</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'seller'">销售</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'worker'">视光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keeper'">库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'optician'">验光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'trainer'">训练师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financial'">财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'physicians'">医师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'machinist'">加工</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'reception'">接待</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'inspect'">特检</span>
                </div>
                <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li>
            
            <li class="nav-item open-item systemColor2" :class="isOpen==1?'open':''" @click="isOpen=1" v-if="adminLimit[0]==1 || adminLimit[1]==1 || adminLimit[3]==1 || adminLimit[2]==1 || adminLimit[4]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-crosshairs-gps" style="margin-right: 14px"></span><i>就诊开单</i></p>
                <span class="mdi mdi-chevron-down"></span>                  
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==1?'active':''" @click="navActive(1)" v-if="adminLimit[0]==1">
                <a class="nav-link" href="/#/Index">
                  <span class="menu-title">主页</span>
                  <i class="mdi mdi-home menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==35?'active':''" @click="navActive(35)" v-if="adminLimit[1]==1">
                <a class="nav-link" href="/#/EyeRegister">
                  <span class="menu-title">登记</span>
                  <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==36?'active':''" @click="navActive(36)" v-if="adminLimit[3]==1">
                <a class="nav-link" href="/#/EyeCustomer">
                  <span class="menu-title">就诊</span>
                  <i class="mdi mdi-account-location menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==6?'active':''" @click="navActive(6)" v-if="adminLimit[2]==1">
                <a class="nav-link" href="/#/Billing">
                  <span class="menu-title">开单</span>
                  <i class="mdi mdi-clipboard-check menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==37?'active':''" @click="navActive(37)" v-if="adminLimit[4]==1">
                <a class="nav-link" href="/#/EyeReport">
                  <span class="menu-title">检查报告</span>
                  <i class="mdi mdi-file-check menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==38?'active':''" @click="navActive(38)">
                <a class="nav-link" href="/#/EyeCharge">
                  <span class="menu-title" :style="{color:nav==38?'#ffffff':'#f2ddff'}">收费</span>
                  <i class="mdi mdi-wallet menu-icon" :style="{color:nav==38?'#ffffff':'#f2ddff'}"></i>
                </a>
              </li> -->
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==9?'open':''" @click="isOpen=9" v-if="adminLimit[23]==1 || adminLimit[38]==1 || adminLimit[24]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-account-settings-variant" style="margin-right: 14px"></span><i>用户管理</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <!-- <li class="nav-item" @click="navActive(43)"> <a class="nav-link" :class="nav==43?'active':''" href="/#/Profiles">用户档案</a></li>
              <li class="nav-item" @click="navActive(44)"> <a class="nav-link" :class="nav==44?'active':''" href="/#/ProfilesTab">定义标签</a></li> -->

              <li class="nav-item" :class="nav==43?'active':''" @click="navActive(43)" v-if="adminLimit[23]==1">
                <a class="nav-link" href="/#/Profiles">
                  <span class="menu-title">用户档案</span>
                  <i class="mdi mdi-clipboard-account menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==48?'active':''" @click="navActive(48)" v-if="adminLimit[38]==1">
                <a class="nav-link" href="/#/MarketingRecharge">
                  <span class="menu-title">用户储值</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==44?'active':''" @click="navActive(44)" v-if="adminLimit[24]==1">
                <a class="nav-link" href="/#/ProfilesTab">
                  <span class="menu-title">定义标签</span>
                  <i class="mdi mdi-bookmark-plus menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==2?'open':''" @click="isOpen=2">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-eye" style="margin-right: 14px"></span><i>眼科</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==39?'active':''" @click="navActive(39)">
                <a class="nav-link" href="/#/EyePackage">
                  <span class="menu-title">眼科套餐</span>
                  <i class="mdi mdi-arrange-bring-forward menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==40?'active':''" @click="navActive(40)">
                <a class="nav-link" href="/#/EyeItem">
                  <span class="menu-title">眼科检查</span>
                  <i class="mdi mdi-stethoscope menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==41?'active':''" @click="navActive(41)">
                <a class="nav-link" href="/#/EyeCheck">
                  <span class="menu-title">检查项目</span>
                  <i class="mdi mdi-tag-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==42?'active':''" @click="navActive(42)">
                <a class="nav-link" href="/#/EyeRegistered">
                  <span class="menu-title">挂号</span>
                  <i class="mdi mdi-camera-enhance menu-icon"></i>
                </a>
                <!-- <div class="border-bottom" v-if="isLogin!=3"></div> -->
              </li>
            </ul>
			<li class="nav-item open-item systemColor2 zhou" :class="isOpen==7?'open':''" @click="isOpen=7;navActive(46)" style="padding:0px;" v-if="adminLimit[36]==1">
			  <div class="border-bottom">
			    <a class="text-secondary" href="/#/MarketingCard" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-numeric-1-box-multiple-outline" style="margin-right: 14px"></span><i>视觉训练</i></a>  
			    <!-- <span class="mdi mdi-chevron-down"></span>    -->                
			  </div>
			</li>
            <li class="nav-item open-item systemColor2 noneZhou" :class="isOpen==7?'open':''" @click="isOpen=7;navActive(46)" style="padding:0px;" v-if="adminLimit[36]==1">
              <div class="border-bottom">
                <a class="text-secondary" href="/#/MarketingCard" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-numeric-1-box-multiple-outline" style="margin-right: 14px"></span><i>月卡次卡</i></a>  
                <!-- <span class="mdi mdi-chevron-down"></span>    -->                
              </div>
            </li>
            <li class="nav-item open-item systemColor2 setting" :class="isOpen==7?'open':''" @click="isOpen=7">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-ticket-confirmation" style="margin-right: 14px"></span><i>应用管理</i></p>  
                <span class="mdi mdi-chevron-down"></span>                   
              </div>
            </li>
            <ul class="systemColor1 setting" style="display:none">
              <li class="nav-item" :class="nav==46?'active':''" @click="navActive(46)">
                <a class="nav-link" href="/#/MarketingCard">
                  <span class="menu-title">视觉训练</span>
                  <i class="mdi mdi-numeric-1-box-multiple-outline menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==47?'active':''" @click="navActive(47)">
                <a class="nav-link setting" href="/#/MarketingIntegral">
                  <span class="menu-title">积分</span>
                  <i class="mdi mdi-tag-text-outline menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==48?'active':''" @click="navActive(48)">
                <a class="nav-link" href="/#/MarketingRecharge">
                  <span class="menu-title">用户储值</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==12?'active':''" @click="navActive(12)">
                <a class="nav-link" href="/#/ProductPackage">
                  <span class="menu-title">商品套餐</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==49?'active':''" @click="navActive(49)">
                <a class="nav-link" href="/#/MarketingSeckill">
                  <span class="menu-title">秒杀</span>
                  <i class="mdi mdi-alarm-check menu-icon"></i>
                </a>
              </li>
            </ul>

            <li class="nav-item open-item systemColor2" :class="isOpen==12?'open':''" @click="isOpen=12;navActive(29)" style="padding:0px;" v-if="adminLimit[29]==1">
              <div class="border-bottom">
                <a class="text-secondary" href="/#/Order" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-clipboard-text" style="margin-right: 14px"></span><i>订单列表</i></a>  
                <!-- <span class="mdi mdi-chevron-down"></span>    -->                
              </div>
            </li>
            <li class="nav-item open-item systemColor2 setting" :class="isOpen==12?'open':''" @click="isOpen=12" v-if="adminLimit[29]==1 || adminLimit[41]==1 || adminLimit[19]==1 || adminLimit[21]==1 || adminLimit[28]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-receipt" style="margin-right: 14px"></span><i>订单列表</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1 setting" style="display:none">
              <li class="nav-item" :class="nav==29?'active':''" @click="navActive(29)" v-if="adminLimit[29]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Order">
                  <span class="menu-title">销售订单</span>
                  <i class="mdi mdi-clipboard-text menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==8?'active':''" @click="navActive(8)" v-if="adminLimit[41]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ProductTransfers">
                  <span class="menu-title">调拨列表</span>
                  <i class="mdi mdi-truck-delivery menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="adminLimit[19]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Stock">
                  <span class="menu-title">进货单</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==24?'active':''" @click="navActive(24)" v-if="adminLimit[21]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Breakage">
                  <span class="menu-title">报损列表</span>
                  <i class="mdi mdi-security menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==19?'active':''" @click="navActive(19)" v-if="adminLimit[28]==1">
                <a class="nav-link" href="/#/ProcessApply">
                  <span class="menu-title">定制品采购</span>
                  <i class="mdi mdi-basket menu-icon"></i>
                </a>
                <!-- <div class="border-bottom" v-if="isLogin!=3"></div> -->
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==3?'open':''" @click="isOpen=3" v-if="adminLimit[11]==1 || adminLimit[13]==1  || adminLimit[43]==1 || adminLimit[41]==1 || adminLimit[19]==1 || adminLimit[21]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-package" style="margin-right: 14px"></span><i>商品管理</i></p>  
                <span class="mdi mdi-chevron-down"></span>                   
              </div>
            </li>
            <ul class="systemColor1">
              <!-- <li class="nav-item setting" :class="nav>=7 && nav<=10 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#product1" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">常规产品</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="product1" :class="nav>=7 && nav<=10 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(7)"> <a class="nav-link" :class="nav==7?'active':''" href="/#/Product">产品列表</a></li>
                    <li class="nav-item" @click="navActive(8)"> <a class="nav-link" :class="nav==8?'active':''" href="/#/ProductNews">新增产品</a></li>
                    <li class="nav-item" @click="navActive(9)"> <a class="nav-link" :class="nav==9?'active':''" href="/#/ProductStock">当前库存</a></li>
                    <li class="nav-item" @click="navActive(10)"> <a class="nav-link" :class="nav==10?'active':''" href="/#/ProductClassify">产品分类</a></li>
                  </ul>
                </div>
              </li>
              <li class="nav-item setting" :class="nav>=11 && nav<=14 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#product2" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">药监产品</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-pill menu-icon"></i>
                </a>
                <div class="collapse" id="product2" :class="nav>=11 && nav<=14 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(11)"> <a class="nav-link" :class="nav==11?'active':''" href="/#/MedicalList">产品列表</a></li>
                    <li class="nav-item" @click="navActive(12)"> <a class="nav-link" :class="nav==12?'active':''" href="/#/MedicalAdd">新增产品</a></li>
                    <li class="nav-item" @click="navActive(13)"> <a class="nav-link" :class="nav==13?'active':''" href="/#/MedicalClassify">药监分类</a></li>
                    <li class="nav-item" @click="navActive(14)"> <a class="nav-link" :class="nav==14?'active':''" href="/#/MedicalBatch">产品批号</a></li>
                  </ul>
                </div>
              </li> -->
              <li class="nav-item" :class="nav==7?'active':''" @click="navActive(7)" v-if="adminLimit[11]==1">
                <a class="nav-link" href="/#/Product">
                  <span class="menu-title">商品目录</span>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==3?'active':''" @click="navActive(3)" v-if="adminLimit[13]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ProductEntry">
                  <span class="menu-title">进销存统计</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==11?'active':''" @click="navActive(11)" v-if="adminLimit[43]==1">
                <a class="nav-link" href="/#/ProductStock">
                  <span class="menu-title">库存查询</span>
                  <i class="mdi mdi-dropbox menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==63?'active':''" @click="navActive(63)" v-if="adminLimit[52]==1">
                <a class="nav-link" href="/#/ProductCheck">
                  <span class="menu-title">库存E:E</span>
                  <i class="mdi mdi-dropbox menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item" :class="nav==10?'active':''" @click="navActive(10)" v-if="adminLimit[41]==1">
                <a class="nav-link" href="/#/ProductTransfers">
                  <span class="menu-title">仓库调拨</span>
                  <i class="mdi mdi-image-filter-frames menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==15?'active':''" @click="navActive(15)" v-if="adminLimit[19]==1">
                <a class="nav-link" href="/#/Stock">
                  <span class="menu-title">进货单</span>
                  <i class="mdi mdi-cart menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==16?'active':''" @click="navActive(16)">
                <a class="nav-link" href="/#/SupplyBarter">
                  <span class="menu-title">换货单</span>
                  <i class="mdi mdi-alert-octagon menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==17?'active':''" @click="navActive(17)" v-if="adminLimit[21]==1">
                <a class="nav-link" href="/#/Breakage">
                  <span class="menu-title">店内报损</span>
                  <i class="mdi mdi-close-box menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" v-if="isLogin=='supplier'" :class="nav==27?'active':''" @click="navActive(27)">
                <a class="nav-link" href="/#/SupplyOrder">
                  <span class="menu-title">发货列表</span>
                  <i class="mdi mdi-truck menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" v-if="isLogin=='supplier'" :class="nav==28?'active':''" @click="navActive(28)">
                <a class="nav-link" href="/#/SupplyBarter">
                  <span class="menu-title">临期换货</span>
                  <i class="mdi mdi-alert-octagon menu-icon"></i>
                </a>
              </li>
            </ul>
            <!-- <li class="nav-item open-item systemColor2" :class="isOpen==3?'open':''" @click="isOpen=3" v-if="adminLimit[11]==1 || adminLimit[22]==1 || adminLimit[43]==1 || adminLimit[41]==1 || adminLimit[19]==1 || adminLimit[21]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-package" style="margin-right: 14px"></span><i>GSP管理</i></p>  
                <span class="mdi mdi-chevron-down"></span>                   
              </div>
            </li> -->
            
            <li class="nav-item open-item systemColor2" :class="isOpen==5?'open':''" @click="isOpen=5" v-if="adminLimit[27]==1 || adminLimit[16]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-pin" style="margin-right: 14px"></span><i>加工中心</i></p>   
                <span class="mdi mdi-chevron-down"></span>                  
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==18?'active':''" @click="navActive(18)" v-if="adminLimit[27]==1"> <!-- 加工师：5 -->
                <a class="nav-link" href="/#/ProcessList">
                  <span class="menu-title">加工列表</span>
                  <i class="mdi mdi-note-text menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==53?'active':''" @click="navActive(53)" v-if="adminLimit[16]==1">
                <a class="nav-link" href="/#/ProcessUser">
                  <span class="menu-title">我的加工</span>
                  <i class="mdi mdi-basket menu-icon"></i>
                </a>
                <!-- <div class="border-bottom" v-if="isLogin!=3"></div> -->
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==11?'open':''" @click="isOpen=11" v-if="adminLimit[33]==1 || adminLimit[40]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-message-processing" style="margin-right: 14px"></span><i>营销管理</i></p>   
                <span class="mdi mdi-chevron-down"></span>                  
              </div>
            </li>
            <ul class="systemColor1 setting">
              <li class="nav-item" :class="nav==45?'active':''" @click="navActive(45)" v-if="adminLimit[33]==1">
                <a class="nav-link" href="/#/MessageSend">
                  <span class="menu-title">短信模板</span>
                  <i class="mdi mdi-file-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==33?'active':''" @click="navActive(33)" v-if="adminLimit[40]==1">
                <a class="nav-link" href="/#/Message">
                  <span class="menu-title">短信统计</span>
                  <i class="mdi mdi-note-text menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==4?'open':''" @click="isOpen=4" v-if="adminLimit[25]==1 || adminLimit[26]==1 || adminLimit[39]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-store" style="margin-right: 14px"></span><i>门店管理</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==2?'active':''" @click="navActive(2)" style="display:none">
                <a class="nav-link" href="/#/Branch">
                  <span class="menu-title">多店管理</span>
                  <i class="mdi mdi-store menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==3?'active':''" @click="navActive(3)">
                <a class="nav-link" href="/#/Profiles">
                  <span class="menu-title" :style="{color:nav==3?'#ffffff':'#f2ddff'}">用户档案</span>
                  <i class="mdi mdi-account-card-details menu-icon" :style="{color:nav==3?'#ffffff':'#f2ddff'}"></i>
                </a>
              </li> -->
              <!-- <li class="nav-item" :class="nav>=43 && nav<=44 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#Profiles" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title" :style="{color:nav>=43 && nav<=44?'#ffffff':'#f2ddff'}">用户管理</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-account-card-details menu-icon" :style="{color:nav>=43 && nav<=44?'#ffffff':'#f2ddff'}"></i>
                </a>
                <div class="collapse" id="Profiles" :class="nav>=43 && nav<=44 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(43)"> <a class="nav-link" :class="nav==43?'active':''" href="/#/Profiles">用户档案</a></li>
                    <li class="nav-item" @click="navActive(44)"> <a class="nav-link" :class="nav==44?'active':''" href="/#/ProfilesTab">定义标签</a></li>
                  </ul>
                </div>
              </li> -->
              <!-- <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title" :style="{color:nav==1?'#ffffff':'#f2ddff'}">用户档案</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-clipboard-account menu-icon" :style="{color:nav==1?'#ffffff':'#f2ddff'}"></i>
                </a>
                <div class="collapse" id="ui-basic">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item"> <a class="nav-link" href="/#/Profiles">学生档案</a></li>
                    <li class="nav-item"> <a class="nav-link" href="/#/Profiles">用户档案</a></li>
                  </ul>
                </div>
              </li> -->
              <li class="nav-item" :class="nav==4?'active':''" @click="navActive(4)" v-if="adminLimit[25]==1">
                <a class="nav-link" href="/#/Employees">
                  <span class="menu-title">员工列表</span>
                  <i class="mdi mdi-contacts menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==65?'active':''" @click="navActive(53)"> <!-- v-if="adminLimit[53]==1" -->
                <a class="nav-link" href="/#/shopEquipment">
                  <span class="menu-title">关联设备</span>
                  <i class="mdi mdi-monitor-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==5?'active':''" @click="navActive(5)" v-if="adminLimit[26]==1">
                <a class="nav-link" href="/#/Supplier">
                  <span class="menu-title">门店供货商</span>
                  <i class="mdi mdi-buffer menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==9?'active':''" @click="navActive(9)" v-if="adminLimit[39]==1">
                <a class="nav-link" href="/#/Manufacturer">
                  <span class="menu-title">生产商列表</span>
                  <i class="mdi mdi-flask-empty menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==8?'open':''" @click="isOpen=8" v-if="adminLimit[30]==1 || adminLimit[31]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-chart-areaspline" style="margin-right: 14px"></span><i>数据统计</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==30?'active':''" @click="navActive(30)" v-if="adminLimit[30]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ChartStore">
                  <span class="menu-title">销售统计</span>
                  <i class="mdi mdi-cart menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==31?'active':''" @click="navActive(31)" v-if="adminLimit[31]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ChartEmployer">
                  <span class="menu-title">绩效数据</span>
                  <i class="mdi mdi-database-plus menu-icon"></i>
                </a>
              </li>
             <!--  <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">成本统计</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li> -->

              <!-- <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">销售数据</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">绩效统计</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">会员概况</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li> -->
              <!-- <li class="nav-item" :class="nav>=30 && nav<=32 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                  <span class="menu-title">图表分析</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-chart-bar menu-icon"></i>
                </a>
                <div class="collapse" id="general-pages" :class="nav>=30 && nav<=32 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(30)"> <a class="nav-link" :class="nav==30?'active':''" href="/#/Chart"> 销售统计 </a></li>
                    <li class="nav-item" @click="navActive(31)"> <a class="nav-link" :class="nav==31?'active':''" href="/#/ChartEmployer"> 利润统计 </a></li>
                    <li class="nav-item" @click="navActive(32)"> <a class="nav-link" :class="nav==32?'active':''" href="/#/ChartCost"> 成本统计 </a></li>
                  </ul>
                </div>
                  
              </li> -->
              <!-- <li class="nav-item" :class="nav==33?'active':''" @click="navActive(33)">
                <a class="nav-link" href="/#/Message">
                  <span class="menu-title">短信</span>
                  <i class="mdi mdi-comment-text menu-icon"></i>
                </a>
              </li>  -->
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==10?'open':''" @click="isOpen=10" v-if="adminLimit[34]==1 || adminLimit[35]==1 || adminLimit[15]==1 || adminLimit[12]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-wrench" style="margin-right: 14px"></span><i>系统设置</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==34?'active':''" @click="navActive(34)" v-if="adminLimit[34]==1">
                <a class="nav-link" href="/#/SetBasis">
                  <span class="menu-title">基本设置</span>
                  <i class="mdi mdi-settings menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==50?'active':''" @click="navActive(50)" v-if="adminLimit[35]==1">
                <a class="nav-link" href="/#/SetWechat">
                  <span class="menu-title">公众号设置</span>
                  <i class="mdi mdi-wechat menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item setting" :class="nav==14?'active':''" @click="navActive(14)">
                <a class="nav-link" href="/#/Batch">
                  <span class="menu-title">商品注册号</span>
                  <i class="mdi mdi-pill menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav>=39 && nav<=42 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#eyes1" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">眼科设置</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="eyes1" :class="nav>=39 && nav<=42 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(39)">
                      <a class="nav-link" :class="nav==39?'active':''" href="/#/EyePackage">眼科套餐</a>
                    </li>
                    <li class="nav-item" @click="navActive(40)">
                      <a class="nav-link" :class="nav==40?'active':''" href="/#/EyeItem">眼科检查</a>
                    </li>
                    <li class="nav-item" @click="navActive(41)">
                      <a class="nav-link" :class="nav==41?'active':''" href="/#/EyeCheck">检查项目</a>
                    </li>
                    <li class="nav-item" @click="navActive(42)">
                      <a class="nav-link" :class="nav==42?'active':''" href="/#/EyeRegistered">挂号</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" :class="nav==51?'active':''" @click="navActive(51)" v-if="adminLimit[15]==1">
                <a class="nav-link" href="/#/SetCard">
                  <span class="menu-title">次卡列表</span>
                  <i class="mdi mdi-cards menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==52?'active':''" @click="navActive(52)" v-if="adminLimit[12]==1">
                <a class="nav-link" href="/#/SetReduction">
                  <span class="menu-title">充值满赠</span>
                  <i class="mdi mdi-code-greater-than-or-equal menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav>=51 && nav<=52 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#eyes2" aria-expanded="false" aria-controls="ui-basic" v-if="adminLimit[15]==1 || adminLimit[12]==1">
                  <span class="menu-title">应用设置</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="eyes2" :class="nav>=51 && nav<=52 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(51)" v-if="adminLimit[15]==1">
                      <a class="nav-link" :class="nav==51?'active':''" href="/#/SetCard">次卡列表
                      </a>
                    </li>
                    <li class="nav-item" @click="navActive(52)" v-if="adminLimit[12]==1">
                      <a class="nav-link" :class="nav==52?'active':''" href="/#/SetReduction">充值满赠
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <li class="nav-item sidebar-actions setting">
              <span class="nav-link" style="white-space: unset;">
                <a href="" download class="btn btn-block btn-lg btn-gradient-primary mt-4 systemColor1" style="margin-top: 0px!important">+ 下载操作手册</a>
                <div class="mt-4">
                  <!-- <div class="border-bottom">
                    <p class="text-secondary">Categories</p>                  
                  </div> -->
                  <div class="advise-bottom">
                    <p class="p1">青少年视力眼科门诊</p>
                    <p class="p2">青少年视力眼科门诊致力于高效便捷的眼科服务。</p>
                    <p class="p2">Tel:15889876958</p>
                    <p class="p2">Add:杭州市白杨街道激光大厦17-374号</p>
                    <img src="./assets/images/advise_icon.png">
                  </div>
                </div>
              </span>
            </li>
          </ul>
          <ul class="nav nav1" v-if="isLogin!='hospitals' && lvNav==false && isLogin!='keepers' && isLogin!='buyers' && isLogin!='financials'" >
            <li class="nav-item nav-profile">
              <a href="/#/Index" class="nav-link">
                <div class="nav-profile-image">
                  <!-- <div style="width:44px;height:44px;background-size: cover;background-repeat: no-repeat;background-position: center" :style="{backgroundImage: 'url('+ systemSet.head +')'}"></div> -->
                  <img :src="userInfo.hospital.head">
                  <span class="login-status online"></span>
                </div>
                <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">{{userInfo.admin.name}}</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospitals'">总管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keepers'">总店库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'buyers'">总店采购</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financials'">总店财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospital'">管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'supplier'">供货商</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'seller'">销售</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'worker'">视光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keeper'">库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'optician'">验光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'trainer'">训练师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financial'">财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'physicians'">医师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'machinist'">加工</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'reception'">接待</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'inspect'">特检</span>
                </div>
                <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==0?'open':''" @click="isOpen=0" v-if="adminLimit[0]==1 || adminLimit[44]==1">
              <!-- <input type="radio" id="1" name="nav" value="1" v-model="isOpen"> -->
              <!-- <li class="nav-item open-item systemColor2" @click="isOpen=0" v-if="adminLimit[0]==1 || adminLimit[1]==1"> -->
              <label class="border-bottom" for="1">
                <p class="text-secondary"><span class="mdi mdi-home" style="margin-right: 14px"></span><i>主页</i></p>
                <span class="mdi mdi-chevron-down"></span>                  
              </label>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==1?'active':''" @click="navActive(1)" v-if="adminLimit[0]==1">
                <a class="nav-link" href="/#/Index">
                  <span class="menu-title">网站首页</span>
                  <i class="mdi mdi-airplay menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==60?'active':''" @click="navActive(60)" v-if="adminLimit[44]==1">
                <a class="nav-link" href="/#/IndexAgent">
                  <span class="menu-title">待办事件</span>
                  <i class="mdi mdi-airballoon menu-icon"></i>
                </a>
              </li>
            </ul>
            <!-- <input type="radio" id="2" name="nav" value="2" v-model="isOpen"> -->
            <li class="nav-item open-item systemColor2" :class="isOpen==1?'open':''"  @click="isOpen=1" v-if="adminLimit[1]==1 || adminLimit[3]==1 || adminLimit[2]==1 || adminLimit[4]==1">
              <label class="border-bottom" for="2">
                <p class="text-secondary"><span class="mdi mdi-crosshairs-gps" style="margin-right: 14px"></span><i>就诊开单</i></p>
                <span class="mdi mdi-chevron-down"></span>                  
              </label>
            </li>
            <ul class="systemColor1">
              <!-- <li class="nav-item" :class="nav==1?'active':''" @click="navActive(1)" v-if="adminLimit[0]==1">
                <a class="nav-link" href="/#/Index">
                  <span class="menu-title">主页</span>
                  <i class="mdi mdi-home menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item" :class="nav==35?'active':''" @click="navActive(35)" v-if="adminLimit[1]==1">
                <a class="nav-link" href="/#/EyeRegister">
                  <span class="menu-title">登记</span>
                  <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==64?'active':''" @click="navActive(64)" v-if="adminLimit[3]==1">
                <a class="nav-link" href="/#/EyeCustomer?tap=1">
                  <span class="menu-title">就诊列表</span>
                  <i class="mdi mdi-account-location menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==6?'active':''" @click="navActive(6)" v-if="adminLimit[2]==1">
                <a class="nav-link" href="/#/Billing">
                  <span class="menu-title">开单</span>
                  <i class="mdi mdi-clipboard-check menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==37?'active':''" @click="navActive(37)" v-if="adminLimit[4]==1">
                <a class="nav-link" href="/#/EyeReport">
                  <span class="menu-title">检查报告</span>
                  <i class="mdi mdi-file-check menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==38?'active':''" @click="navActive(38)">
                <a class="nav-link" href="/#/EyeCharge">
                  <span class="menu-title" :style="{color:nav==38?'#ffffff':'#f2ddff'}">收费</span>
                  <i class="mdi mdi-wallet menu-icon" :style="{color:nav==38?'#ffffff':'#f2ddff'}"></i>
                </a>
              </li> -->
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==9?'open':''" @click="isOpen=9" v-if="adminLimit[23]==1 || adminLimit[38]==1 || adminLimit[24]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-account-settings-variant" style="margin-right: 14px"></span><i>用户管理</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <!-- <li class="nav-item" @click="navActive(43)"> <a class="nav-link" :class="nav==43?'active':''" href="/#/Profiles">用户档案</a></li>
              <li class="nav-item" @click="navActive(44)"> <a class="nav-link" :class="nav==44?'active':''" href="/#/ProfilesTab">定义标签</a></li> -->

              <li class="nav-item" :class="nav==43?'active':''" @click="navActive(43)" v-if="adminLimit[23]==1">
                <a class="nav-link" href="/#/Profiles">
                  <span class="menu-title">用户档案</span>
                  <i class="mdi mdi-clipboard-account menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==48?'active':''" @click="navActive(48)" v-if="adminLimit[38]==1">
                <a class="nav-link" href="/#/MarketingRecharge">
                  <span class="menu-title">用户储值</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==44?'active':''" @click="navActive(44)" v-if="adminLimit[24]==1">
                <a class="nav-link" href="/#/ProfilesTab">
                  <span class="menu-title">定义标签</span>
                  <i class="mdi mdi-bookmark-plus menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2 setting" :class="isOpen==2?'open':''" @click="isOpen=2">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-eye" style="margin-right: 14px"></span><i>眼科1</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1 setting" style="display: none;">
              <!-- <li class="nav-item" :class="nav==39?'active':''" @click="navActive(39)">
                <a class="nav-link" href="/#/EyePackage">
                  <span class="menu-title">眼科套餐</span>
                  <i class="mdi mdi-arrange-bring-forward menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item" :class="nav==40?'active':''" @click="navActive(40)">
                <a class="nav-link" href="/#/EyeItem">
                  <span class="menu-title">眼科检查</span>
                  <i class="mdi mdi-stethoscope menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==41?'active':''" @click="navActive(41)">
                <a class="nav-link" href="/#/EyeCheck">
                  <span class="menu-title">检查项目</span>
                  <i class="mdi mdi-tag-multiple menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==42?'active':''" @click="navActive(42)">
                <a class="nav-link" href="/#/EyeRegistered">
                  <span class="menu-title">挂号</span>
                  <i class="mdi mdi-camera-enhance menu-icon"></i>
                </a>
              </li> -->
            </ul>
			<li class="nav-item open-item systemColor2" style="padding:0px;" :class="isOpen==12?'open':''" @click="isOpen=12;navActive(29)" v-if="adminLimit[29]==1">
			  <div class="border-bottom">
				<a class="text-secondary" href="/#/Order" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-clipboard-text" style="margin-right: 14px"></span><i>订单列表</i></a>             
			  </div>
			</li>
			
			<!-- <li class="nav-item open-item systemColor2" :class="isOpen==12?'open':''" @click="isOpen=12" v-if="adminLimit[29]==1 || adminLimit[54]==1">
			  <div class="border-bottom">
			    <p class="text-secondary"><span class="mdi mdi-receipt" style="margin-right: 14px"></span><i>订单列表</i></p> 
			    <span class="mdi mdi-chevron-down"></span>                    
			  </div>
			</li>
			<ul class="systemColor1">
				<li class="nav-item" :class="nav==29?'active':''" @click="navActive(29)" v-if="adminLimit[29]==1">
				  <a class="nav-link" href="/#/Order">
				    <span class="menu-title">商品订单</span>
				    <i class="mdi mdi-clipboard-text menu-icon"></i>
				  </a>
				</li>
				<li class="nav-item" :class="nav==64?'active':''" @click="navActive(64)" v-if="adminLimit[54]==1">
				  <a class="nav-link" href="/#/OrderCheck">
				    <span class="menu-title">检查项目</span>
				    <i class="mdi mdi-arrange-bring-forward menu-icon"></i>
				  </a>
				</li>
			</ul> -->

            <li class="nav-item open-item systemColor2 setting" :class="isOpen==12?'open':''" @click="isOpen=12" v-if="adminLimit[41]==1 || adminLimit[19]==1 || adminLimit[21]==1 || adminLimit[28]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-receipt" style="margin-right: 14px"></span><i>订单列表</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1 setting" style="display:none">
              <li class="nav-item" :class="nav==29?'active':''" @click="navActive(29)" v-if="adminLimit[29]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Order">
                  <span class="menu-title">销售订单</span>
                  <i class="mdi mdi-clipboard-text menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==8?'active':''" @click="navActive(8)" v-if="adminLimit[41]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ProductTransfers">
                  <span class="menu-title">调拨列表</span>
                  <i class="mdi mdi-truck-delivery menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="adminLimit[19]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Stock">
                  <span class="menu-title">商品采购</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==61?'active':''" @click="navActive(61)" v-if="adminLimit[45]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/StockWare">
                  <span class="menu-title">入库列表</span>
                  <i class="mdi mdi-clipboard-arrow-down menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==24?'active':''" @click="navActive(24)" v-if="adminLimit[21]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Breakage">
                  <span class="menu-title">报损列表</span>
                  <i class="mdi mdi-security menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==19?'active':''" @click="navActive(19)" v-if="adminLimit[28]==1">
                <a class="nav-link" href="/#/ProcessApply">
                  <span class="menu-title">定制品采购</span>
                  <i class="mdi mdi-basket menu-icon"></i>
                </a>
                <!-- <div class="border-bottom" v-if="isLogin!=3"></div> -->
              </li>
            </ul>
            <li class="nav-item open-item systemColor2 zhou" :class="isOpen==7?'open':''" @click="isOpen=7;navActive(46)" style="padding:0px;" v-if="adminLimit[36]==1">
              <div class="border-bottom">
                <a class="text-secondary" href="/#/MarketingCard" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-numeric-1-box-multiple-outline" style="margin-right: 14px"></span><i>视觉训练</i></a>  
                <!-- <span class="mdi mdi-chevron-down"></span>    -->                
              </div>
            </li>
			<li class="nav-item open-item systemColor2 noneZhou" :class="isOpen==7?'open':''" @click="isOpen=7;navActive(46)" style="padding:0px;" v-if="adminLimit[36]==1">
			  <div class="border-bottom">
			    <a class="text-secondary" href="/#/MarketingCard" style="display:block;width:100%;padding: 15px 1rem;"><span class="mdi mdi-numeric-1-box-multiple-outline" style="margin-right: 14px"></span><i>月卡次卡</i></a>  
			    <!-- <span class="mdi mdi-chevron-down"></span>    -->                
			  </div>
			</li>
            <li class="nav-item open-item systemColor2 setting" :class="isOpen==7?'open':''" @click="isOpen=7">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-ticket-confirmation" style="margin-right: 14px"></span><i>应用管理</i></p>  
                <span class="mdi mdi-chevron-down"></span>                   
              </div>
            </li>
            <ul class="systemColor1 setting" style="display:none">
              <li class="nav-item" :class="nav==46?'active':''" @click="navActive(46)">
                <a class="nav-link" href="/#/MarketingCard">
                  <span class="menu-title">视觉训练</span>
                  <i class="mdi mdi-numeric-1-box-multiple-outline menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==47?'active':''" @click="navActive(47)">
                <a class="nav-link setting" href="/#/MarketingIntegral">
                  <span class="menu-title">积分</span>
                  <i class="mdi mdi-tag-text-outline menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==48?'active':''" @click="navActive(48)">
                <a class="nav-link" href="/#/MarketingRecharge">
                  <span class="menu-title">用户储值</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==12?'active':''" @click="navActive(12)">
                <a class="nav-link" href="/#/ProductPackage">
                  <span class="menu-title">商品套餐</span>
                  <i class="mdi mdi-account-card-details menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==49?'active':''" @click="navActive(49)">
                <a class="nav-link" href="/#/MarketingSeckill">
                  <span class="menu-title">秒杀</span>
                  <i class="mdi mdi-alarm-check menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==3?'open':''" @click="isOpen=3" v-if="adminLimit[11]==1 || adminLimit[13]==1 || adminLimit[43]==1 || adminLimit[41]==1 || adminLimit[19]==1 || adminLimit[21]==1 || adminLimit[8]==1 || adminLimit[10]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-package" style="margin-right: 14px"></span><i>产品管理</i></p>  
                <span class="mdi mdi-chevron-down"></span>                   
              </div>
            </li>
            <ul class="systemColor1">
              <!-- <li class="nav-item setting" :class="nav>=7 && nav<=10 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#product1" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">常规产品</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="product1" :class="nav>=7 && nav<=10 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(7)"> <a class="nav-link" :class="nav==7?'active':''" href="/#/Product">产品列表</a></li>
                    <li class="nav-item" @click="navActive(8)"> <a class="nav-link" :class="nav==8?'active':''" href="/#/ProductNews">新增产品</a></li>
                    <li class="nav-item" @click="navActive(9)"> <a class="nav-link" :class="nav==9?'active':''" href="/#/ProductStock">当前库存</a></li>
                    <li class="nav-item" @click="navActive(10)"> <a class="nav-link" :class="nav==10?'active':''" href="/#/ProductClassify">产品分类</a></li>
                  </ul>
                </div>
              </li>
              <li class="nav-item setting" :class="nav>=11 && nav<=14 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#product2" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">药监产品</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-pill menu-icon"></i>
                </a>
                <div class="collapse" id="product2" :class="nav>=11 && nav<=14 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(11)"> <a class="nav-link" :class="nav==11?'active':''" href="/#/MedicalList">产品列表</a></li>
                    <li class="nav-item" @click="navActive(12)"> <a class="nav-link" :class="nav==12?'active':''" href="/#/MedicalAdd">新增产品</a></li>
                    <li class="nav-item" @click="navActive(13)"> <a class="nav-link" :class="nav==13?'active':''" href="/#/MedicalClassify">药监分类</a></li>
                    <li class="nav-item" @click="navActive(14)"> <a class="nav-link" :class="nav==14?'active':''" href="/#/MedicalBatch">产品批号</a></li>
                  </ul>
                </div>
              </li> -->
              <li class="nav-item" :class="nav==7?'active':''" @click="navActive(7)" v-if="adminLimit[11]==1">
                <a class="nav-link" href="/#/Product">
                  <span class="menu-title">商品目录</span>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==3?'active':''" @click="navActive(3)" v-if="adminLimit[13]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ProductEntry">
                  <span class="menu-title">进销存统计</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==11?'active':''" @click="navActive(11)" v-if="adminLimit[43]==1">
                <a class="nav-link" href="/#/ProductStock">
                  <span class="menu-title">库存查询</span>
                  <i class="mdi mdi-equal-box menu-icon"></i>
                </a>
              </li>
              <li class="nav-item noneZhou" :class="nav==63?'active':''" @click="navActive(63)" v-if="adminLimit[52]==1">
                <a class="nav-link" href="/#/ProductCheck">
                  <span class="menu-title">库存盘点</span>
                  <i class="mdi mdi-dropbox menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==10?'active':''" @click="navActive(10)" v-if="adminLimit[41]==1">
                <a class="nav-link" href="/#/ProductTransfers">
                  <span class="menu-title">仓库调拨</span>
                  <i class="mdi mdi-image-filter-frames menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="adminLimit[19]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Stock">
                  <span class="menu-title" v-if="isAdd==1">商品采购</span>
                  <span class="menu-title" v-else>商品补货</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==61?'active':''" @click="navActive(61)" v-if="adminLimit[45]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/StockWare">
                  <span class="menu-title">入库列表</span>
                  <i class="mdi mdi-clipboard-arrow-down menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav==16?'active':''" @click="navActive(16)">
                <a class="nav-link" href="/#/SupplyBarter">
                  <span class="menu-title">换货单</span>
                  <i class="mdi mdi-alert-octagon menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==17?'active':''" @click="navActive(17)" v-if="adminLimit[21]==1">
                <a class="nav-link" href="/#/Breakage">
                  <span class="menu-title">店内报损</span>
                  <i class="mdi mdi-close-box menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" v-if="isLogin=='supplier'" :class="nav==27?'active':''" @click="navActive(27)">
                <a class="nav-link" href="/#/SupplyOrder">
                  <span class="menu-title">发货列表</span>
                  <i class="mdi mdi-truck menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" v-if="isLogin=='supplier'" :class="nav==28?'active':''" @click="navActive(28)">
                <a class="nav-link" href="/#/SupplyBarter">
                  <span class="menu-title">临期换货</span>
                  <i class="mdi mdi-alert-octagon menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==62?'active':''" @click="navActive(62)" v-if="adminLimit[10]==1">
                <a class="nav-link" href="/#/Return">
                  <span class="menu-title">商品退回</span>
                  <i class="mdi mdi-logout menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==13?'open':''" @click="isOpen=13" v-if="adminLimit[50]==1 || adminLimit[51]==1 || adminLimit[46]==1 || adminLimit[47]==1 || adminLimit[48]==1 || adminLimit[49]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-flask" style="margin-right: 14px"></span><i>GSP管理</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==54?'active':''" @click="navActive(54)" v-if="adminLimit[46]==1">
                <a class="nav-link" href="/#/GspFirm">
                  <span class="menu-title">首营企业审批</span>
                  <i class="mdi mdi-bank menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==55?'active':''" @click="navActive(55)" v-if="adminLimit[47]==1">
                <a class="nav-link" href="/#/GspCommodity">
                  <span class="menu-title">首营商品审批</span>
                  <i class="mdi mdi-marker menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==56?'active':''" @click="navActive(56)" v-if="adminLimit[48]==1">
                <a class="nav-link" href="/#/GSPValidity">
                  <span class="menu-title">有效期预警</span>
                  <i class="mdi mdi-clock-start menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==57?'active':''" @click="navActive(57)" v-if="adminLimit[49]==1">
                <a class="nav-link" href="/#/GspBatch">
                  <span class="menu-title">批号表</span>
                  <i class="mdi mdi-texture menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==58?'active':''" @click="navActive(58)" v-if="adminLimit[50]==1">
                <a class="nav-link" href="/#/GspDisinfect">
                  <span class="menu-title">消毒记录</span>
                  <i class="mdi mdi-timer-off menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==59?'active':''" @click="navActive(59)" v-if="adminLimit[51]==1">
                <a class="nav-link" href="/#/GspAccept">
                  <span class="menu-title">医疗器械验收</span>
                  <i class="mdi mdi-star-circle menu-icon"></i>
                </a>
              </li>
            </ul>

            <li class="nav-item open-item systemColor2" :class="isOpen==4?'open':''" @click="isOpen=4" v-if="adminLimit[25]==1 || adminLimit[26]==1 || adminLimit[39]==1 || adminLimit[53]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-store" style="margin-right: 14px"></span><i>门店管理</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==2?'active':''" @click="navActive(2)" style="display:none">
                <a class="nav-link" href="/#/Branch">
                  <span class="menu-title">多店管理</span>
                  <i class="mdi mdi-store menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==3?'active':''" @click="navActive(3)">
                <a class="nav-link" href="/#/Profiles">
                  <span class="menu-title" :style="{color:nav==3?'#ffffff':'#f2ddff'}">用户档案</span>
                  <i class="mdi mdi-account-card-details menu-icon" :style="{color:nav==3?'#ffffff':'#f2ddff'}"></i>
                </a>
              </li> -->
              <!-- <li class="nav-item" :class="nav>=43 && nav<=44 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#Profiles" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title" :style="{color:nav>=43 && nav<=44?'#ffffff':'#f2ddff'}">用户管理</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-account-card-details menu-icon" :style="{color:nav>=43 && nav<=44?'#ffffff':'#f2ddff'}"></i>
                </a>
                <div class="collapse" id="Profiles" :class="nav>=43 && nav<=44 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(43)"> <a class="nav-link" :class="nav==43?'active':''" href="/#/Profiles">用户档案</a></li>
                    <li class="nav-item" @click="navActive(44)"> <a class="nav-link" :class="nav==44?'active':''" href="/#/ProfilesTab">定义标签</a></li>
                  </ul>
                </div>
              </li> -->
              <!-- <li class="nav-item">
                <a class="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title" :style="{color:nav==1?'#ffffff':'#f2ddff'}">用户档案</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-clipboard-account menu-icon" :style="{color:nav==1?'#ffffff':'#f2ddff'}"></i>
                </a>
                <div class="collapse" id="ui-basic">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item"> <a class="nav-link" href="/#/Profiles">学生档案</a></li>
                    <li class="nav-item"> <a class="nav-link" href="/#/Profiles">用户档案</a></li>
                  </ul>
                </div>
              </li> -->
              <li class="nav-item" :class="nav==4?'active':''" @click="navActive(4)" v-if="adminLimit[25]==1">
                <a class="nav-link" href="/#/Employees">
                  <span class="menu-title">员工列表</span>
                  <i class="mdi mdi-contacts menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==65?'active':''" @click="navActive(65)" v-if="adminLimit[53]==1">
                <a class="nav-link" href="/#/shopEquipment">
                  <span class="menu-title">关联设备</span>
                  <i class="mdi mdi-monitor-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==5?'active':''" @click="navActive(5)" v-if="adminLimit[26]==1">
                <a class="nav-link" href="/#/Supplier">
                  <span class="menu-title">门店供货商</span>
                  <i class="mdi mdi-buffer menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==9?'active':''" @click="navActive(9)" v-if="adminLimit[39]==1">
                <a class="nav-link" href="/#/Manufacturer">
                  <span class="menu-title">生产商列表</span>
                  <i class="mdi mdi-flask-empty menu-icon"></i>
                </a>
              </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==5?'open':''" @click="isOpen=5" v-if="adminLimit[27]==1 || adminLimit[16]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-pin" style="margin-right: 14px"></span><i>产品加工</i></p>   
                <span class="mdi mdi-chevron-down"></span>                  
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==18?'active':''" @click="navActive(18)" v-if="adminLimit[27]==1"> <!-- 加工师：5 -->
                <a class="nav-link" href="/#/ProcessList">
                  <span class="menu-title">加工列表</span>
                  <i class="mdi mdi-note-text menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==53?'active':''" @click="navActive(53)" v-if="adminLimit[16]==1">
                <a class="nav-link" href="/#/ProcessUser">
                  <span class="menu-title">我的加工</span>
                  <i class="mdi mdi-basket menu-icon"></i>
                </a>
                <!-- <div class="border-bottom" v-if="isLogin!=3"></div> -->
              </li>
            </ul>

            <li class="nav-item open-item systemColor2" :class="isOpen==11?'open':''" @click="isOpen=11" v-if="adminLimit[33]==1 || adminLimit[40]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-message-processing" style="margin-right: 14px"></span><i>短信通知</i></p>   
                <span class="mdi mdi-chevron-down"></span>                  
              </div>
            </li>
            <ul class="systemColor1 setting">
              <li class="nav-item" :class="nav==45?'active':''" @click="navActive(45)" v-if="adminLimit[33]==1">
                <a class="nav-link" href="/#/MessageSend">
                  <span class="menu-title">短信模板</span>
                  <i class="mdi mdi-file-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==33?'active':''" @click="navActive(33)" v-if="adminLimit[40]==1">
                <a class="nav-link" href="/#/Message">
                  <span class="menu-title">短信统计</span>
                  <i class="mdi mdi-note-text menu-icon"></i>
                </a>
              </li>
            </ul>
            <!-- <li class="nav-item"> 供货商：3 
              <div class="border-bottom">
                <p class="text-secondary">供货商</p>                  
              </div>
            </li> -->
            <!-- <li class="nav-item" :class="nav>=20 && nav<=22 ? 'active':''">
              <a class="nav-link" data-toggle="collapse" href="#product3" aria-expanded="false" aria-controls="ui-basic">
                <span class="menu-title" :style="{color:nav>=20 && nav<=22?'#ffffff':'#f2ddff'}">常规产品</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-view-dashboard menu-icon" :style="{color:nav>=20 && nav<=22?'#ffffff':'#f2ddff'}"></i>
              </a>
              <div class="collapse" id="product3" :class="nav>=20 && nav<=22 ? 'show':''">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item" @click="navActive(20)"> <a class="nav-link" :class="nav==20?'active':''" :style="{color:nav==20?'#ffffff':'#f2ddff'}" href="/#/Product">产品列表</a></li>
                  <li class="nav-item" @click="navActive(21)"> <a class="nav-link" :class="nav==21?'active':''" :style="{color:nav==21?'#ffffff':'#f2ddff'}" href="/#/ProductNews">新增产品</a></li>
                  <li class="nav-item" @click="navActive(22)"> <a class="nav-link" :class="nav==22?'active':''" :style="{color:nav==22?'#ffffff':'#f2ddff'}" href="/#/ProductClassify">产品分类</a></li>
                </ul>
              </div>
            </li>
            <li class="nav-item" :class="nav>=23 && nav<=26 ? 'active':''">
              <a class="nav-link" data-toggle="collapse" href="#product4" aria-expanded="false" aria-controls="ui-basic">
                <span class="menu-title" :style="{color:nav>=23 && nav<=26?'#ffffff':'#f2ddff'}">药监产品</span>
                <i class="menu-arrow"></i>
                <i class="mdi mdi-pill menu-icon" :style="{color:nav>=23 && nav<=26?'#ffffff':'#f2ddff'}"></i>
              </a>
              <div class="collapse" id="product4" :class="nav>=23 && nav<=26 ? 'show':''">
                <ul class="nav flex-column sub-menu">
                  <li class="nav-item" @click="navActive(23)"> <a class="nav-link" :class="nav==23?'active':''" :style="{color:nav==23?'#ffffff':'#f2ddff'}" href="/#/MedicalList">产品列表</a></li>
                  <li class="nav-item" @click="navActive(24)"> <a class="nav-link" :class="nav==24?'active':''" :style="{color:nav==24?'#ffffff':'#f2ddff'}" href="/#/MedicalAdd">新增产品</a></li>
                  <li class="nav-item" @click="navActive(25)"> <a class="nav-link" :class="nav==25?'active':''" :style="{color:nav==25?'#ffffff':'#f2ddff'}" href="/#/MedicalClassify">产品分类</a></li>
                  <li class="nav-item" @click="navActive(26)"> <a class="nav-link" :class="nav==26?'active':''" :style="{color:nav==26?'#ffffff':'#f2ddff'}" href="/#/MedicalBatch">产品批号</a></li>
                </ul>
              </div>
            </li> -->
            <li class="nav-item open-item systemColor2" :class="isOpen==8?'open':''" @click="isOpen=8" v-if="adminLimit[30]==1 || adminLimit[31]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-chart-areaspline" style="margin-right: 14px"></span><i>数据统计</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==30?'active':''" @click="navActive(30)" v-if="adminLimit[30]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ChartStore">
                  <span class="menu-title">销售统计</span>
                  <i class="mdi mdi-cart menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==31?'active':''" @click="navActive(31)" v-if="adminLimit[31]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/ChartEmployer">
                  <span class="menu-title">绩效数据</span>
                  <i class="mdi mdi-database-plus menu-icon"></i>
                </a>
              </li>
             <!--  <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">成本统计</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li> -->

              <!-- <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">销售数据</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">绩效统计</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==32?'active':''" @click="navActive(32)" v-if="adminLimit[32]==1">
                <a class="nav-link" href="/#/ChartCost">
                  <span class="menu-title">会员概况</span>
                  <i class="mdi mdi-cash-multiple menu-icon"></i>
                </a>
              </li> -->
              <!-- <li class="nav-item" :class="nav>=30 && nav<=32 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                  <span class="menu-title">图表分析</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-chart-bar menu-icon"></i>
                </a>
                <div class="collapse" id="general-pages" :class="nav>=30 && nav<=32 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(30)"> <a class="nav-link" :class="nav==30?'active':''" href="/#/Chart"> 销售统计 </a></li>
                    <li class="nav-item" @click="navActive(31)"> <a class="nav-link" :class="nav==31?'active':''" href="/#/ChartEmployer"> 利润统计 </a></li>
                    <li class="nav-item" @click="navActive(32)"> <a class="nav-link" :class="nav==32?'active':''" href="/#/ChartCost"> 成本统计 </a></li>
                  </ul>
                </div>
                  
              </li> -->
              <!-- <li class="nav-item" :class="nav==33?'active':''" @click="navActive(33)">
                <a class="nav-link" href="/#/Message">
                  <span class="menu-title">短信</span>
                  <i class="mdi mdi-comment-text menu-icon"></i>
                </a>
              </li>  -->
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==10?'open':''" @click="isOpen=10" v-if="adminLimit[34]==1 || adminLimit[35]==1 || adminLimit[15]==1 || adminLimit[12]==1">
              <div class="border-bottom">
                <p class="text-secondary"><span class="mdi mdi-wrench" style="margin-right: 14px"></span><i>系统设置</i></p> 
                <span class="mdi mdi-chevron-down"></span>                    
              </div>
            </li>
            <ul class="systemColor1">
              <li class="nav-item" :class="nav==34?'active':''" @click="navActive(34)" v-if="adminLimit[34]==1">
                <a class="nav-link" href="/#/SetBasis">
                  <span class="menu-title">基本设置</span>
                  <i class="mdi mdi-settings menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==50?'active':''" @click="navActive(50)" v-if="adminLimit[35]==1">
                <a class="nav-link" href="/#/SetWechat">
                  <span class="menu-title">公众号设置</span>
                  <i class="mdi mdi-wechat menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item setting" :class="nav==14?'active':''" @click="navActive(14)">
                <a class="nav-link" href="/#/Batch">
                  <span class="menu-title">商品注册号</span>
                  <i class="mdi mdi-pill menu-icon"></i>
                </a>
              </li>
              <li class="nav-item noneZhouF" :class="nav>=40 && nav<=41 ? 'active':''" v-if="adminLimit[8]==1 || adminLimit[9]==1">
                <a class="nav-link" data-toggle="collapse" href="#eyes1" aria-expanded="false" aria-controls="ui-basic">
                  <span class="menu-title">眼科设置</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="eyes1" :class="nav>=40 && nav<=41 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(40)" v-if="adminLimit[8]==1">
                      <a class="nav-link" :class="nav==40?'active':''" href="/#/EyeItem">眼科检查</a>
                    </li>
                    <li class="nav-item" @click="navActive(41)" v-if="adminLimit[9]==1">
                      <a class="nav-link" :class="nav==41?'active':''" href="/#/EyeCheck">检查项目</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item" :class="nav==51?'active':''" @click="navActive(51)" v-if="adminLimit[15]==1">
                <a class="nav-link" href="/#/SetCard">
                  <span class="menu-title">次卡列表</span>
                  <i class="mdi mdi-cards menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==52?'active':''" @click="navActive(52)" v-if="adminLimit[12]==1">
                <a class="nav-link" href="/#/SetReduction">
                  <span class="menu-title">充值满赠</span>
                  <i class="mdi mdi-code-greater-than-or-equal menu-icon"></i>
                </a>
              </li>
              <li class="nav-item setting" :class="nav>=51 && nav<=52 ? 'active':''">
                <a class="nav-link" data-toggle="collapse" href="#eyes2" aria-expanded="false" aria-controls="ui-basic" v-if="adminLimit[15]==1 || adminLimit[12]==1">
                  <span class="menu-title">应用设置</span>
                  <i class="menu-arrow"></i>
                  <i class="mdi mdi-view-dashboard menu-icon"></i>
                </a>
                <div class="collapse" id="eyes2" :class="nav>=51 && nav<=52 ? 'show':''">
                  <ul class="nav flex-column sub-menu">
                    <li class="nav-item" @click="navActive(51)" v-if="adminLimit[15]==1">
                      <a class="nav-link" :class="nav==51?'active':''" href="/#/SetCard">次卡列表
                      </a>
                    </li>
                    <li class="nav-item" @click="navActive(52)" v-if="adminLimit[12]==1">
                      <a class="nav-link" :class="nav==52?'active':''" href="/#/SetReduction">充值满赠
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
            <li class="nav-item sidebar-actions setting">
              <span class="nav-link" style="white-space: unset;">
                <a href="" download class="btn btn-block btn-lg btn-gradient-primary mt-4 systemColor1" style="margin-top: 0px!important">+ 下载操作手册</a>
                <div class="mt-4">
                  <!-- <div class="border-bottom">
                    <p class="text-secondary">Categories</p>                  
                  </div> -->
                  <div class="advise-bottom">
                    <p class="p1">青少年视力眼科门诊</p>
                    <p class="p2">青少年视力眼科门诊致力于高效便捷的眼科服务。</p>
                    <p class="p2">Tel:15889876958</p>
                    <p class="p2">Add:杭州市白杨街道激光大厦17-374号</p>
                    <img src="./assets/images/advise_icon.png">
                  </div>
                </div>
              </span>
            </li>
          </ul>
          <ul class="nav nav2" v-if="isLogin=='hospitals' || isLogin=='keepers' || isLogin=='buyers' || isLogin=='financials'">
            <li class="nav-item nav-profile">
              <a href="/#/Index" class="nav-link">
                <div class="nav-profile-image">
                  <!-- <div style="width:44px;height:44px;background-size: cover;background-repeat: no-repeat;background-position: center" :style="{backgroundImage: 'url('+ systemSet.head +')'}"></div> -->
                  <img :src="userInfo.hospital.head">
                  <span class="login-status online"></span>
                </div>
                <div class="nav-profile-text d-flex flex-column">
                  <span class="font-weight-bold mb-2">{{admin.name}}</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospitals'">总管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keepers'">总店库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'buyers'">总店采购</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financials'">总店财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'hospital'">管理员</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'supplier'">供货商</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'seller'">销售</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'worker'">视光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'keeper'">库管</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'optician'">验光师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'trainer'">训练师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'financial'">财务</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'physicians'">医师</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'machinist'">加工</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'reception'">接待</span>
                  <span class="text-secondary text-small" v-if="isLogin == 'inspect'">特检</span>
                </div>
                <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==1?'open':''" @click="isOpen=1;nav=0" v-if="isLogin=='hospitals'">
              <a class="border-bottom"  href="/#/Branch">
                <p class="text-secondary"><i>多店管理</i></p> 
                <span class="mdi mdi-store"></span>                    
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==14?'open':''" @click="isOpen=14;nav=99" v-if="isLogin=='hospitals'">
              <a class="border-bottom"  href="/#/Employees">
                <p class="text-secondary"><i>总店员工</i></p> 
                <span class="mdi mdi-contacts"></span>                    
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==15?'open':''" @click="isOpen=15;nav=100" v-if="adminLimit[44]==1">
              <a class="border-bottom" href="/#/IndexAgent">
                <p class="text-secondary"><i>待办事件</i></p> 
                <span class="mdi mdi-airballoon menu-icon"></span>
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==11?'open':''" @click="isOpen=11" v-if="adminLimit[2]==1 || adminLimit[3]==1 || adminLimit[4]==1">
              <div class="border-bottom">
                <p class="text-secondary"><i>总店商品</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul>
              <li class="nav-item"  :class="nav==2?'active':''" @click="navActive(2)" v-if="adminLimit[2]==1">
                  <a class="nav-link" href="/#/Product">
                    <span class="menu-title">商品目录</span>
                    <i class="mdi mdi-view-dashboard menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item"  :class="nav==3?'active':''" @click="navActive(3)" v-if="adminLimit[3]==1">  <!-- 总店：1 店长:2、销售:4-->
                  <a class="nav-link" href="/#/ProductEntry">
                    <span class="menu-title">进销存统计</span>
                    <i class="mdi mdi-note-plus menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item"  :class="nav==4?'active':''" @click="navActive(4)" v-if="adminLimit[4]==1">
                  <a class="nav-link" href="/#/ProductStock">
                    <span class="menu-title">库存查询</span>
                    <i class="mdi mdi-equal-box menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item noneZhou" :class="nav==63?'active':''" @click="navActive(63)" v-if="adminLimit[21]==1">
                  <a class="nav-link" href="/#/ProductCheck">
                    <span class="menu-title">库存盘点</span>
                    <i class="mdi mdi-dropbox menu-icon"></i>
                  </a>
                </li>
            </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==12?'open':''" @click="isOpen=12" v-if="adminLimit[5]==1 || adminLimit[6]==1 || adminLimit[7]==1 || adminLimit[8]==1 || adminLimit[9]==1 || adminLimit[10]==1">
              <div class="border-bottom">
                <p class="text-secondary"><i>进货调拨</i></p>    
                <span class="mdi mdi-chevron-down"></span>                 
              </div>
            </li>
            <ul>
              <li class="nav-item" :class="nav==22?'active':''" @click="navActive(22)" v-if="adminLimit[5]==1">
                <a class="nav-link" href="/#/ProductTransfers">
                  <span class="menu-title">仓库调拨</span>
                  <i class="mdi mdi-image-filter-frames menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="isAllStock==1 && adminLimit[6]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/StockAll">
                  <span class="menu-title">商品采购</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==25?'active':''" @click="navActive(25)" v-if="isAllStock==1 && adminLimit[7]==1"> <!-- =1邵宇峰类型 -->
                <a class="nav-link" href="/#/RepleAll">
                  <span class="menu-title">分店补货</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="isAllStock==0 && adminLimit[6]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/Stock">
                  <span class="menu-title">商品采购</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li>
              <!-- <li class="nav-item" :class="nav==23?'active':''" @click="navActive(23)" v-if="isAllStock==0">
                <a class="nav-link" href="/#/Reple">
                  <span class="menu-title">分店补货</span>
                  <i class="mdi mdi-note-plus menu-icon"></i>
                </a>
              </li> -->
              <li class="nav-item" :class="nav==24?'active':''" @click="navActive(24)" v-if="adminLimit[8]==1">  <!-- 总店：1 店长:2、销售:4-->
                <a class="nav-link" href="/#/StockWare">
                  <span class="menu-title">入库列表</span>
                  <i class="mdi mdi-clipboard-arrow-down menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==17?'active':''" @click="navActive(17)" v-if="adminLimit[9]==1">
                <a class="nav-link" href="/#/Breakage">
                  <span class="menu-title">店内报损</span>
                  <i class="mdi mdi-close-box menu-icon"></i>
                </a>
              </li>
              <li class="nav-item" :class="nav==26?'active':''" @click="navActive(26)" v-if="adminLimit[10]==1">
                <a class="nav-link" href="/#/Return">
                  <span class="menu-title">商品退回</span>
                  <i class="mdi mdi-logout menu-icon"></i>
                </a>
              </li>
            </ul>
              
              <li class="nav-item open-item systemColor2" :class="isOpen==13?'open':''" @click="isOpen=13" v-if="adminLimit[11]==1 || adminLimit[12]==1 || adminLimit[13]==1 || adminLimit[14]==1 || adminLimit[15]==1 || adminLimit[16]==1">
                <div class="border-bottom">
                  <p class="text-secondary"><i>GSP管理</i></p>    
                  <span class="mdi mdi-chevron-down"></span>                 
                </div>
              </li>
              <ul class="systemColor1">
                <li class="nav-item" :class="nav==54?'active':''" @click="navActive(54)" v-if="adminLimit[11]==1">
                  <a class="nav-link" href="/#/GspFirm">
                    <span class="menu-title">首营企业审批</span>
                    <i class="mdi mdi-home menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item" :class="nav==55?'active':''" @click="navActive(55)" v-if="adminLimit[12]==1">
                  <a class="nav-link" href="/#/GspCommodity">
                    <span class="menu-title">首营商品审批</span>
                    <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item" :class="nav==56?'active':''" @click="navActive(56)" v-if="adminLimit[13]==1">
                  <a class="nav-link" href="/#/GSPValidity">
                    <span class="menu-title">有效期预警</span>
                    <i class="mdi mdi-account-multiple-plus menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item" :class="nav==57?'active':''" @click="navActive(57)" v-if="adminLimit[14]==1">
                  <a class="nav-link" href="/#/GspBatch">
                    <span class="menu-title">批号表</span>
                    <i class="mdi mdi-account-location menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item" :class="nav==58?'active':''" @click="navActive(58)" v-if="adminLimit[15]==1">
                  <a class="nav-link" href="/#/GspDisinfect">
                    <span class="menu-title">消毒记录</span>
                    <i class="mdi mdi-clipboard-check menu-icon"></i>
                  </a>
                </li>
                <li class="nav-item" :class="nav==59?'active':''" @click="navActive(59)" v-if="adminLimit[16]==1">
                  <a class="nav-link" href="/#/GspAccept">
                    <span class="menu-title">医疗器械验收</span>
                    <i class="mdi mdi-file-check menu-icon"></i>
                  </a>
                </li>
              </ul>
            <li class="nav-item open-item systemColor2" :class="isOpen==7?'open':''" @click="isOpen=7;nav=77" v-if="adminLimit[17]==1">
              <a class="border-bottom"  href="/#/ChartStore">
                <p class="text-secondary"><i>销售统计</i></p> 
                <span class="mdi mdi-speedometer"></span>                    
              </a>
            </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==8?'open':''" @click="isOpen=8;nav=78" v-if="adminLimit[18]==1">
              <a class="border-bottom"  href="/#/Supplier">
                <p class="text-secondary"><i>门店供货商</i></p> 
                <span class="mdi mdi-speedometer"></span>                    
              </a>
            </li>

              <li class="nav-item open-item systemColor2" :class="isOpen==9?'open':''" @click="isOpen=9;nav=79" v-if="adminLimit[19]==1">
                <a class="border-bottom" href="/#/Manufacturer">
                  <p class="text-secondary"><i>生产商列表</i></p> 
                  <span class="mdi mdi-flask-empty menu-icon"></span>
                </a>
              </li>
            <li class="nav-item open-item systemColor2" :class="isOpen==10?'open':''" @click="isOpen=10;nav=80" v-if="adminLimit[20]==1">
              <a class="border-bottom"  href="/#/SetBasis">
                <p class="text-secondary"><i>系统设置</i></p> 
                <span class="mdi mdi-settings"></span>                
              </a>
            </li>
            <li class="nav-item sidebar-actions setting">
              <span class="nav-link" style="white-space: unset;">
                <a href="" download class="btn btn-block btn-lg btn-gradient-primary mt-4 systemColor1" style="margin-top: 0px!important">+ 下载操作手册</a>
                <div class="mt-4">
                  <!-- <div class="border-bottom">
                    <p class="text-secondary">Categories</p>                  
                  </div> -->
                  <div class="advise-bottom">
                    <p class="p1">青少年视力眼科门诊</p>
                    <p class="p2">青少年视力眼科门诊致力于高效便捷的眼科服务。</p>
                    <p class="p2">Tel:15889876958</p>
                    <p class="p2">Add:杭州市白杨街道激光大厦17-374号</p>
                    <img src="./assets/images/advise_icon.png">
                  </div>
                </div>
              </span>
            </li>
          </ul>
        </div> 
      </nav>
      <!-- partial -->
      <div class="main-panel" style="height: 200px;overflow-y: auto;padding-top: 70px;">
        <div class="content-wrapper">
          <router-view @change="navs" @wait="waitType"></router-view>
        </div>
        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
        <!-- <footer class="footer">
          <div class="d-sm-flex justify-content-center justify-content-sm-between">
            <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright &copy; 2018.Company name All rights reserved.<a target="_blank" href="http://sc.chinaz.com/moban/">&#x7F51;&#x9875;&#x6A21;&#x677F;</a></span>
          </div>
        </footer> -->
        <!-- partial -->
      </div>
      <!-- main-panel ends -->

      <div class="htmlProgress" :class="isWait==1?'on':'close'"></div>
    </div>
    <!-- page-body-wrapper ends -->
  </div>
  <div class="login-alert" :class="isAlerts?'act':''">
    <span class="mdi mdi-alert-outline"></span><p style="color: #555">{{isMsg}}</p>
  </div>
</template>

<script>
var dx;
var screenWidth;
const axios = require('axios');
// axios.defaults.baseURL = '/apis';
export default {
  name: 'App',
  data () {
    return {
      verify1:'',
      verify2:'',
      verify3:'',
      verifyOk:'',
      isMsg:'错误',
      forget:0,
      pmdAct: false,
      isAlerts: false,
      siteUrl:'https://www.xxmsljkgl.com',
      // siteUrl: 'https://e.flysoll.com',
      // siteUrl: 'https://yh.wjcross.com',
	  // siteUrl: 'https://v3.flysoll.com',
      webHash: '/',
      nav:1,
      admin:{id:localStorage.getItem('aid'),name:localStorage.getItem('loginName'),img:localStorage.getItem('loginName'),limit:localStorage.getItem('loginLimit')},
      userInfo:{},
      isAdd: localStorage.getItem('udata')?JSON.parse(localStorage.getItem('udata')).hospital.is_add:1,
      isLogin: 0,
      isScreen: 0,
      isKeep: [true],
      isOpen:0,
      isWait:0,
      userName: '',
      userPassword: '',
      dateNoProps: '',
      adminLimit:[],
      timer:0,
      codeShow:1,
      x:'',
      flags: false,
      flagss: false,
      Navtxt1:'',
      Navtxt2:'',
      Navtxt3:'',
      lvNav:false,
      isNav: false,
      isAllStock:0,
      setDiscount: localStorage.getItem('discount'),
      systemSet:{img:'',colortype:'1',color2:'',color1:'',logo:''},
    }
  },
  watch:{
    nav(news,old){
      var _self = this;
      this.nav = news;
      localStorage.setItem('nav',news);
      this.isWait = 1;
      if(news!=1){
        setTimeout(function(){
          _self.isNav = false;
            if(document.querySelectorAll(".sidebar .nav .nav-item.open-item.open .text-secondary i")[0]){
              _self.Navtxt1 = document.querySelectorAll(".sidebar .nav .nav-item.open-item.open .text-secondary i")[0].innerHTML;
            }
            if(document.querySelectorAll(".sidebar .nav .nav-item.active .menu-title")[0]){
              _self.Navtxt2 = document.querySelectorAll(".sidebar .nav .nav-item.active .menu-title")[0].innerHTML;
              _self.isWait = 0;
            }
            if(document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active").length != 0){
              _self.Navtxt3 = document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active")[0].innerHTML;
              _self.isNav = true;
            }
        },600);
        if(news==36){
          localStorage.removeItem('ecName');
          localStorage.removeItem('ecSid');
          localStorage.removeItem('ecClass1');
          localStorage.removeItem('ecClass2');
        }
        if(news==37){
          localStorage.removeItem('erNo');
          localStorage.removeItem('erName');
          localStorage.removeItem('erWid');
          localStorage.removeItem('erSid');
          localStorage.removeItem('erClass1');
          localStorage.removeItem('erClass2');
          localStorage.removeItem('erStart');
          localStorage.removeItem('erEnd');
        }
        if(news == 43){
          localStorage.removeItem('prTab');
          localStorage.removeItem('prName');
          localStorage.removeItem('erWid');
          localStorage.removeItem('prSid');
          localStorage.removeItem('prClass1');
          localStorage.removeItem('prClass2');
          localStorage.removeItem('prStart');
          localStorage.removeItem('prEnd');
        }
        if(news ==48){
          localStorage.removeItem('mrSid');
          localStorage.removeItem('mrClass1');
          localStorage.removeItem('mrClass2');
          localStorage.removeItem('mrName');
          localStorage.removeItem('mrCard');
          localStorage.removeItem('mrBlane');
        }
        if(news == 29){
          localStorage.removeItem('orStart');
          localStorage.removeItem('orEnd');
          localStorage.removeItem('orWid');
          localStorage.removeItem('orStatus');
          localStorage.removeItem('orName');
          localStorage.removeItem('orGname');
          localStorage.removeItem('orSn');
          localStorage.removeItem('orSid');
          localStorage.removeItem('orClass1');
          localStorage.removeItem('orClass2');
        }
      }else{
        _self.Navtxt1 = '';
        _self.Navtxt2 = '';
        _self.Navtxt3 = '';
      }
    }
  },
  created(){
    if(localStorage.getItem('userName')){
      this.userName = localStorage.getItem('userName');
      this.userPassword = localStorage.getItem('userPassword');
      this.isKeep = [true];
    }
    console.log(this.isAdd)
    if(this.isAllStock = JSON.parse(localStorage.getItem('udata'))){
      this.isAllStock = JSON.parse(localStorage.getItem('udata')).hospital.is_all_stock;
      this.isAdd = JSON.parse(localStorage.getItem('udata')).hospital.is_add;
    }
    if(localStorage.getItem('loginLimit')){
      // this.adminLimit = JSON.parse('['+this.admin.limit+']');
      this.adminLimit = this.admin.limit.split(',');
      for(var i=0;i<56;i++){
        if(this.adminLimit[i]==undefined || this.adminLimit[i]==''){
          this.adminLimit[i] = 1;
        }
      }
      console.log(2,this.adminLimit[54])
    }
    // 验证
    this.verify1 = Math.floor(Math.random()*10);
    this.verify2 = Math.floor(Math.random()*10);
    this.verify3 = this.verify1 + this.verify2;
    
    this.isLogin = localStorage.getItem('isLogin');
    if(this.isLogin!='hospitals' && this.isLogin!='hospital'){}
    //导航显色
    this.webHash = window.location.hash.replace('#/','');
    this.webHash?this.webHash:this.webHash = 'Index'
    if(this.webHash == 'Index'){
      localStorage.setItem('nav',1);
      this.isOpen = 0;
    }else if(this.webHash == 'IndexAgent'){
      localStorage.setItem('nav',60);
      this.isOpen = 0;
    }else if(this.webHash == 'Branch'){
      if(this.isLogin!='hospitals'){
        localStorage.setItem('nav',2);
        this.isOpen = 4;
      }else{
        this.isOpen = 1;
        localStorage.setItem('nav',1);
      }
    }else if(this.webHash == 'ProductEntry'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 11;
        localStorage.setItem('nav',3);
      }else{
        localStorage.setItem('nav',3);
        this.isOpen = 3;
      }
        
    }else if(this.webHash == 'shopEquipment'){
      localStorage.setItem('nav',65);
      this.isOpen = 4;
    }else if(this.webHash == 'Employees'){
      localStorage.setItem('nav',4);
      this.isOpen = 4;
    }else if(this.webHash == 'Supplier'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 8;
      }else{
        localStorage.setItem('nav',5);
        this.isOpen = 4;
      }
        
    }else if(this.webHash.indexOf('Billing') != '-1'){
      localStorage.setItem('nav',6);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('ProductTransfers') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',22);
        this.isOpen = 12;
      }else{
        localStorage.setItem('nav',10);
        this.isOpen = 3;
      }
        
    }else if(this.webHash.indexOf('Manufacturer') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 9;
      }else{
        localStorage.setItem('nav',9);
        this.isOpen = 4;
      }
      
    }else if(this.webHash.indexOf('ProductTransfersN') != '-1'){
      localStorage.setItem('nav',10);
      this.isOpen = 3;
    }else if(this.webHash.indexOf('ProductStock') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 11;
        localStorage.setItem('nav',4);
      }else{
        localStorage.setItem('nav',11);
        this.isOpen = 3;
      }
        
    }else if(this.webHash.indexOf('ProductCheck') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 11;
        localStorage.setItem('nav',63);
      }else{
        localStorage.setItem('nav',63);
        this.isOpen = 3;
      }
    }else if(this.webHash.indexOf('Product') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',2);
        this.isOpen = 11;
      }else{
        localStorage.setItem('nav',7);
        this.isOpen = 3;
      }
    }else if(this.webHash == 'ProductPackage'){
      localStorage.setItem('nav',12);
      this.isOpen = 7;
    }else if(this.webHash == 'Batch'){
      localStorage.setItem('nav',14);
      this.isOpen = 3;
    }else if(this.webHash.indexOf('StockApply') != '-1'){
      localStorage.setItem('nav',15);
      this.isOpen = 3;
    }else if(this.webHash == 'SupplyBarter'){
      localStorage.setItem('nav',16);
      this.isOpen = 3;
    }else if(this.webHash == 'SupplyDetails'){
      localStorage.setItem('nav',16);
      this.isOpen = 3;
    }else if(this.webHash.indexOf('BreakageApply') != '-1'){
      localStorage.setItem('nav',17);
      this.isOpen = 3;
    }else if(this.webHash == 'ProcessList'){
      localStorage.setItem('nav',18);
      this.isOpen = 5;
    }else if(this.webHash == 'ProcessApply'){
      localStorage.setItem('nav',19);
      this.isOpen = 5;
    }else if(this.webHash == 'ProcessDetails'){
      localStorage.setItem('nav',19);
      this.isOpen = 5;
    }else if(this.webHash == 'Stock'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',23);
        this.isOpen = 12;
      }else{
        localStorage.setItem('nav',23);
        this.isOpen = 3;
      }
       
    }else if(this.webHash == 'StockAll'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',23);
        this.isOpen = 12;
      } 
    }else if(this.webHash == 'RepleAll'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',25);
        this.isOpen = 12;
      } 
    }else if(this.webHash == 'Return'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',26);
        this.isOpen = 12;
      }else{
        localStorage.setItem('nav',62);
        this.isOpen = 3;
      }
    }else if(this.webHash.indexOf('StockDetails') != '-1'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 6;
      }else{
        localStorage.setItem('nav',15);
        this.isOpen = 3;
      }
    }else if(this.webHash=='StockWare'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        localStorage.setItem('nav',24);
        this.isOpen = 12;
      }else{
        localStorage.setItem('nav',61);
        this.isOpen = 3;
      }
    }else if(this.webHash=='StockWare'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 6;
      }else{
        localStorage.setItem('nav',61);
        this.isOpen = 3;
      }
    }else if(this.webHash == 'Breakage'){
      localStorage.setItem('nav',17);
      this.isOpen = 3;
    }else if(this.webHash.indexOf('BreakageDetails') != '-1'){
      localStorage.setItem('nav',17);
      this.isOpen = 3;
    }else if(this.webHash == 'SupplyOrder'){
      localStorage.setItem('nav',27);
    }else if(this.webHash == 'SupplyMinute'){
      localStorage.setItem('nav',27);
    }else if(this.webHash == 'SupplyBarter'){
      localStorage.setItem('nav',28);
    }else if(this.webHash.indexOf('SupplierDetails') != '-1'){
      localStorage.setItem('nav',28);
      this.isOpen = 4;
    }else if(this.webHash == 'Order'){
      if(this.isLogin!='hospitals'){
        localStorage.setItem('nav',29);
        this.isOpen = 12;
      }else{
        this.isOpen = 2;
      }
    }else if(this.webHash.indexOf('OrderDetails') != '-1'){
      localStorage.setItem('nav',29);
      if(this.isLogin!='hospitals'){
        localStorage.setItem('nav',29);
        this.isOpen = 12;
      }else{
        this.isOpen = 2;
      }
    }else if(this.webHash == 'ChartStore'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 7;
      }else{
        localStorage.setItem('nav',30);
        this.isOpen = 8;
      }
        
    }else if(this.webHash == 'ChartEmployer'){
      localStorage.setItem('nav',31);
      this.isOpen = 8;
    }else if(this.webHash == 'ChartCost'){
      localStorage.setItem('nav',32);
      this.isOpen = 8;
    }else if(this.webHash == 'Message'){
      localStorage.setItem('nav',33);
      this.isOpen = 11;
    }else if(this.webHash == 'SetBasis'){
      if(this.isLogin=='hospitals' || this.isLogin=='keepers'){
        this.isOpen = 10;
      }else{
        localStorage.setItem('nav',34);
        this.isOpen = 10;
      }
    }else if(this.webHash == 'EyeRegister'){
      localStorage.setItem('nav',35);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('EyeCustomer') != '-1'){
      localStorage.setItem('nav',64);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('EyeOptometry') != '-1'){
      localStorage.setItem('nav',36);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('EyeVisit') != '-1'){
      localStorage.setItem('nav',36);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('EyeCaseDetails') != '-1'){
      localStorage.setItem('nav',36);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('OptometryDetails') != '-1'){
      localStorage.setItem('nav',36);
      this.isOpen = 1;
    }else if(this.webHash.indexOf('EyeSelectDetails') != '-1'){
      localStorage.setItem('nav',36);
      this.isOpen = 1;
    }else if(this.webHash == 'EyeReport'){
      localStorage.setItem('nav',37);
      this.isOpen = 1;
    }else if(this.webHash == 'EyeCharge'){
      localStorage.setItem('nav',38);
      this.isOpen = 1;
    }else if(this.webHash == 'EyePackage'){
      localStorage.setItem('nav',39);
      this.isOpen = 2;
    }else if(this.webHash == 'EyeItem'){
      localStorage.setItem('nav',40);
      this.isOpen = 10;
    }else if(this.webHash == 'EyeCheck'){
      localStorage.setItem('nav',41);
      this.isOpen = 10;
    }else if(this.webHash == 'EyeRegistered'){
      localStorage.setItem('nav',42);
      this.isOpen = 7;
    }else if(this.webHash == 'ProfilesTab'){
      localStorage.setItem('nav',44);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('ProfilesReceipt') != '-1'){
      localStorage.setItem('nav',43);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('ProfilesCase') != '-1'){
      localStorage.setItem('nav',43);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('ProfilesOptometry') != '-1'){
      localStorage.setItem('nav',43);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('ProfilesReport') != '-1'){
      localStorage.setItem('nav',43);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('Profiles') != '-1'){
      localStorage.setItem('nav',43);
      this.isOpen = 9;
    }else if(this.webHash == 'MessageSend'){
      localStorage.setItem('nav',45);
      this.isOpen = 11;
    }else if(this.webHash == 'MarketingCard'){
      localStorage.setItem('nav',46);
      this.isOpen = 7;
    }else if(this.webHash == 'MarketingIntegral'){
      localStorage.setItem('nav',47);
      this.isOpen = 7;
    }else if(this.webHash == 'MarketingRecharge'){
      localStorage.setItem('nav',48);
      this.isOpen = 9;
    }else if(this.webHash.indexOf('MarketingAcrive') != '-1'){
      localStorage.setItem('nav',48);
      this.isOpen = 9;
    }else if(this.webHash == 'MarketingSeckill'){
      localStorage.setItem('nav',49);
      this.isOpen = 7;
    }else if(this.webHash == 'SetWechat'){
      localStorage.setItem('nav',50);
      this.isOpen = 10;
    }else if(this.webHash == 'SetCard'){
      localStorage.setItem('nav',51);
      this.isOpen = 10;
    }else if(this.webHash == 'SetReduction'){
      localStorage.setItem('nav',52);
      this.isOpen = 10;
    }else if(this.webHash == 'ProcessUser'){
      localStorage.setItem('nav',53);
      this.isOpen = 5;
    }else if(this.webHash == 'GspFirm'){
      localStorage.setItem('nav',54);
      this.isOpen = 13;
    }else if(this.webHash == 'GspCommodity'){
      localStorage.setItem('nav',55);
      this.isOpen = 13;
    }else if(this.webHash == 'GSPValidity'){
      localStorage.setItem('nav',56);
      this.isOpen = 13;
    }else if(this.webHash == 'GspBatch'){
      localStorage.setItem('nav',57);
      this.isOpen = 13;
    }else if(this.webHash == 'GspDisinfect'){
      localStorage.setItem('nav',58);
      this.isOpen = 13;
    }else if(this.webHash == 'GspAccept'){
      localStorage.setItem('nav',59);
      this.isOpen = 13;
    }else if(this.webHash == 'OrderCheck'){
      localStorage.setItem('nav',64);
      this.isOpen = 12;
    }else{
      if(this.isLogin!='hospitals'){
        localStorage.setItem('nav',34);
        this.isOpen = 10;
      }else{
        this.isOpen = 3;
      }
    }
    localStorage.getItem('nav')?this.nav=localStorage.getItem('nav'):'1';
    //是否登录
    if(localStorage.getItem('isLogin') && localStorage.getItem('isLogin')!='0'){
      var timeOut = (new Date() - new Date(localStorage.getItem('loginTime')))/(36000*1*24);
      // // console.log(timeOut)
      if(timeOut<=100){
        // 登录一天之内
        this.userInfo = JSON.parse(localStorage.getItem('udata'));
        // console.log('总',this.userInfo)
        if(this.isLogin == 'all'){
          this.$router.push('/Administrator');
        }else{
          this.$router.push(this.webHash);
        }
      }else{
        this.logout();
      }
    }else{
      this.isLogin = 0;
      this.$router.push('/')
    }
    var _self = this;
    var style = document.createElement("style");
    document.head.appendChild(style);
    var sheet = style.sheet;
    // var url = `/api/index/web?name=${window.location.host}`;
    // alert(window.location.host);

    var url = this.siteUrl+`/api/index/web?name=${window.location.host}`;
    if(window.location.host.indexOf('dlsg.flysoll.com') != -1){
      this.lvNav = true;
    }
    axios.get(url).then(function(res){
      // console.log('sys',res);
      _self.systemSet.img = `${res.data.data.background}`;
      _self.systemSet.head = `${res.data.data.head}`;
      // console.log(_self.systemSet.head )
      _self.systemSet.logo = `${res.data.data.light_logo}`;
      _self.systemSet.logo2 = `${res.data.data.logo}`;
      _self.systemSet.colortype = res.data.data.color_type;
      _self.systemSet.color1 = res.data.data.color3;
      _self.systemSet.color2 = res.data.data.color2;
      // 背景深
    // sheet.addRule('.c-2 li:hover,.c-1 li:hover,.alert .alert-box button.systemColor2,.deposit ul p:hover,.card.systemColor2,.wrapper.systemColor2,nav.systemColor2,.btn.systemColor2,.paging-item.paging-item--current.systemColor2,.form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before,.form-check .form-check-label input[type="radio"]:checked + .input-helper:before,.no-schedule[data-step="1"] .progresses:after,.no-schedule .progresses:after','background:'+_self.systemSet.color1+'!important');
    // 
    // // 背景浅
    // sheet.addRule('.btn.systemColor1,.sidebar .nav .nav-item.open-item.open, .sidebar .nav .nav-item.open-item:hover,.sidebar .nav .nav-item.open-item + ul,.paging-item:hover,.no-schedule .progresses:before','background:'+_self.systemSet.color2+'!important');
    // // 单选多选
    // sheet.addRule('.form-check .form-check-label input[type="checkbox"] + .input-helper:before,.form-check .form-check-label input[type="radio"] + .input-helper:before,.btn.btn-fw.systemColor3','border-color:'+_self.systemSet.color1+'!important');

    // // 文字深
    // sheet.addRule('.workGroup li .p2 span.systemColor2,p.systemColor2,.card .card-title.systemColor2,.card-title .act,.card .card-title span.mdi,.card-title a:hover,.listSummary .p1 span.systemColor2,.btn.btn-fw.systemColor3','color:'+_self.systemSet.color1+'!important');

      sheet.addRule(':root','--color-active:'+_self.systemSet.color2+'!important');
      sheet.addRule(':root','--color-theme:'+_self.systemSet.color1+'!important');
      sheet.addRule('.content-wrapper,.showuse,.btn-group.systemColor1,.module .module-product','background:'+_self.systemSet.color1+'21');
      sheet.addRule('.card-leftSys','border-color:'+_self.systemSet.color1+'21!important');
    })

    console.log('123',this.userInfo)
  },
  mounted(){
    if(this.isLogin != 'all' && document.getElementById('sidebar')){
      document.getElementById('sidebar').style.height = '200px';
    }
    if(document.body.clientWidth > 980){
      this.codeShow = 1
    }else{
      this.codeShow = 2;
    }
    this.isNav = false;
    if(this.nav != '1'){
      if(this.Navtxt1 = document.querySelectorAll(".sidebar .nav .nav-item.open-item.open .text-secondary i")[0]){
        this.Navtxt1 = document.querySelectorAll(".sidebar .nav .nav-item.open-item.open .text-secondary i")[0].innerHTML;
      }
      if(document.querySelectorAll(".sidebar .nav .nav-item.active .menu-title")[0]){
        this.Navtxt2 = document.querySelectorAll(".sidebar .nav .nav-item.active .menu-title")[0].innerHTML;
      }
      // document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active")[0].innerHTML;
      // console.log(document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active").length)
      if(document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active").length != 0){
        this.Navtxt3 = document.querySelectorAll(".sidebar .nav.sub-menu .nav-item .nav-link.active")[0].innerHTML;
        this.isNav = true;
      }
    }else{
      this.Navtxt1 = '';
      this.Navtxt2 = '';
      this.Navtxt3 = '';
    }
  },
  // beforeDestroy(){
  //   if(this.isKeep[0] == false){
  //     var _self = this;
  //     _self.isLogin = '0';
  //     localStorage.setItem("isLogin",'0');
  //     localStorage.removeItem("uid");
  //     localStorage.removeItem("loginTime");
  //   }
  // },
  methods:{
    navs(data){
      this.nav = data.nav;
      this.isOpen = data.isOpen;
    },
    waitType(){
      this.isWait = 0;
    },
    // 登录
    toLogin(){
      var _self = this;
      // _self.isLogin = 'hospitals';
      // localStorage.setItem('isLogin',_self.isLogin); // 登录状态和账号角色,显示页面权限
      // localStorage.setItem('uid','11');  // 账号id ， 显示列表数据
      // localStorage.setItem('loginTime',new Date());
      // if(this.isLogin == 'admin'){
      //   this.$router.push('/Administrator')
      // }else{
      //   this.$router.push('/Index');
      // }
      // location.reload();
      // console.log(document.querySelector('.yzm').style.color)
      if(this.verify3 == this.verifyOk){
        if(this.userName != '' || this.userPassword != ''){
          
          axios.get(this.siteUrl+'/api/login/index?username='+ this.userName +'&password='+ this.userPassword).then(function(res){
            _self.userInfo = res.data.data;
            console.log('admin',_self.userInfo)
            if(res.data.code=='1'){
              if(_self.isKeep[0]){
                localStorage.setItem('userName',_self.userName);
                localStorage.setItem('userPassword',_self.userPassword);
              }else{
                localStorage.removeItem('userName');
                localStorage.removeItem('userPassword');
              }
              _self.isLogin = res.data.data.type;
              localStorage.setItem('isLogin',res.data.data.type); // 登录状态和账号角色,显示页面权限
              localStorage.setItem('discount',res.data.data.admin.dis); // 登录状态和账号角色,显示页面权限
              localStorage.setItem('uid',res.data.data.hid);  // 账号id ， 显示列表数据
              localStorage.setItem('udata',JSON.stringify(res.data.data));  // 账号id ， 显示列表数据
              localStorage.setItem('loginTime',new Date());
              localStorage.setItem('loginName',res.data.data.admin.name);
              localStorage.setItem('loginImg',res.data.data.hospital.logo);
              localStorage.setItem('id',res.data.data.aid);
              localStorage.setItem('aid',res.data.data.hospital.aid);
              _self.adminLimit = res.data.data.admin.limit.split(',');
              for(var i=0;i<55;i++){
                if(_self.adminLimit[i]==undefined || _self.adminLimit[i]==''){
                  _self.adminLimit[i] = 1;
                }
              }
              localStorage.setItem('loginLimit',_self.adminLimit);
              // console.log(res.data.data.admin.limit)
              // console.log(res.data.data)
              // localStorage.setItem('loginHospital', JSON.stringify(res.data.data.hospital));
              // localStorage.setItem('loginAdmin',JSON.stringify(res.data.data.admin));
              if(_self.isLogin == 'all'){
                _self.$router.push('/Administrator')
              }else if(_self.isLogin == 'hospitals'){
                _self.$router.push('/Branch')
              }else{
                _self.$router.push('/Index');
                _self.isOpen = 0;
                _self.nav = 1;
              }
              // location.reload();
            }else{
              _self.verifyNews();
              _self.isMsg = res.data.msg;
              _self.$message.warning(_self.isMsg);
            }
          })
        }else{
          this.verifyNews();
          this.isMsg = '请输入的账号密码!';
          _self.$message.warning(_self.isMsg);
        }
      }else{
        this.verifyNews();
        this.isMsg = '请输入正确的值，完成验证！';
        _self.$message.warning(_self.isMsg);
      }  
    },
    verifyNews(){
      this.verify1 = Math.floor(Math.random()*10);
      this.verify2 = Math.floor(Math.random()*10);
      this.verify3 = this.verify1 + this.verify2;
      this.verifyOk = '';
    },
    toReload(){
      location.reload();
    },
    navActive(i){
      this.nav = i;
    },
    // 退出登录
    logout(){
      var _self = this;
      _self.isLogin = '0';
      localStorage.setItem("isLogin",'0');
      localStorage.removeItem("uid");
      localStorage.removeItem("loginTime");
      _self.$router.push('/Index');
      location.reload();
      // axios.get(''+ this.userName +'&pwd='+ this.hospitalPa).then(function(res){
      //   // console.log('成功后返回登录页面！')
      // })
    },
    // 全屏
    requestFullscreen () {
      const docElm = document.documentElement
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      }
      this.isScreen = 1;
    },
    // 全屏
    exitFullScreen () {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
      this.isScreen = 0;
    },
    pmdNav(){
      this.pmdAct = !this.pmdAct;
    },
    toForget(i){
      this.forget = i
    },
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
          touch = event.touches[0];
      } else {
          touch = event;
      }
      screenWidth = document.querySelector('.code2').offsetWidth
      dx = touch.clientX - event.target.offsetLeft
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
            touch = event.touches[0];
        } else {
            touch = event;
        }
        // 定位滑块的位置
        // this.x = touch.clientX - dx;
        // 限制滑块超出页面
        if (this.x < 0) {
            this.x = 0
        } else if (this.x < screenWidth - 45) {
          this.x = touch.clientX - dx;
          document.querySelector('.b').style.right = document.querySelector('.code2').offsetWidth - this.x + 'px'
          if(this.x + 46 >= document.querySelector('.code2').offsetWidth){
            document.querySelector('.yzm').style.color = '#8bc34a';
            document.querySelector('.yzm').classList = 'mdi mdi-checkbox-marked-circle-outline yzm'
          }else{
            document.querySelector('.yzm').style.color = '#aaa';
            document.querySelector('.yzm').classList = 'mdi mdi-chevron-double-right yzm'
          }
            // this.x = screenWidth - touch.target.clientWidth
        }
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove", function () {
            event.preventDefault();
        }, false);
      }
    },
    //鼠标释放时候的函数
    end() {
        console.log('end')
        this.flags = false;
    },
    toProduct(){
      localStorage.setItem('nav',7);
      this.isOpen = 3;
      this.$router.push({path:'/ProductStock',query: {alarm:'-1'}})
    },
    toOrder(){
      if(this.isLogin!='hospitals'){
        localStorage.setItem('nav',29);
        this.isOpen = 12;
      }else{
        this.isOpen = 2;
      }
      this.$router.push({path:'/Order'})
    },
  },
  directives:{
    wahaha(el,binding){
      el.onmousedown = function(ev){
        document.querySelector('.yzm').setAttribute('data-flagss','1');
        var x = ev.clientX - el.offsetLeft;
        el.onmousemove = function(ev){
          var flagss =  document.querySelector('.yzm').getAttribute('data-flagss');
          // console.log(ev.offsetX)
          if(ev.offsetX>=2 && ev.offsetX<=43 && ev.offsetY>=2 && ev.offsetY<=43){
            if(flagss == '1'){
              this.x = ev.clientX - x + 'px';
              if(ev.clientX - x > 0 && ev.clientX - x + 44 <= document.querySelector('.code').offsetWidth){
                document.querySelector('.b').style.right = document.querySelector('.code').offsetWidth - ev.clientX + x + 'px'
                el.style.left = this.x
                if(ev.clientX - x + 46 >= document.querySelector('.code').offsetWidth){
                  el.style.color = '#8bc34a';
                  el.classList = 'mdi mdi-checkbox-marked-circle-outline yzm';
                  document.querySelector('.yzm').setAttribute('data-flagss','0');
                }else{
                  el.style.color = '#aaa';
                  el.classList = 'mdi mdi-chevron-double-right yzm'
                }
              }
            } 
          }else{
            document.querySelector('.yzm').setAttribute('data-flagss','0');
            console.log('移出')
          }
        }  
      }
      el.onmouseup = function(ev){
        document.querySelector('.yzm').setAttribute('data-flagss','0');
      }
      return false
    }
  },
  components: {}
};
</script>

<style>
@import './assets/css/vendor.bundle.base.css';
@import './assets/css/materialdesignicons.min.css';
@import './assets/css/style.css';
@import './assets/css/main.css';
:root{
  --color-active: #333;
  --color-theme: #555;
}
.pcNav .ani{
  transition-duration: 0.1s;
}
.pcNav .ani.close{
  position: relative;
  left:0px;
  opacity:1;
}
.pcNav .ani.on{
  position: relative;
  left:15px;
  opacity:0;
}
.htmlProgress{
  position: fixed;
  top: 0px;
  left:0px;
  right: 0px;
  height:3px;
  z-index: 9999;
}
.htmlProgress:before{
  content:'';
  height:3px;
  width: 0%;
  opacity:0.6;
  background-color: var(--color-theme);
  /*transition-duration: 0.2s;
  transition-timing-function: ease-out;*/
}
.htmlProgress.on:before{
  display:flex;
  animation: itProgress 0.3s ease-out;
}
.htmlProgress.close:before{
  display:none;
}
@keyframes itProgress
{
  0%{display:flex;width:0%;}
  100%{display:flex;width:100%;}
}

@-webkit-keyframes mymove /* Safari and Chrome */
{
  from {top:0px;}
  to {top:200px;}
}
.page-header{display:none!important;}
a{
  text-decoration: none!important;
}
body{
  margin: 0px;
  padding: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px;
  padding: 0px;
}
::-webkit-scrollbar {/*滚动条整体样式*/
  width: 0px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  background: #999;
}

::-webkit-scrollbar-track {/*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}

.table-responsive>div::-webkit-scrollbar{
  width:3px;
}
.table-responsive>div::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #444;
}
.table-responsive>div::-webkit-scrollbar-track {/*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}
.classify::-webkit-scrollbar{
  width:3px;
}
.classify::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #444;
}
.classify::-webkit-scrollbar-track {/*滚动条里面轨道*/
  border-radius: 10px;
  background: #EDEDED;
}

.haveSrocll::-webkit-scrollbar{
  width:3px;
}
.haveSrocll::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  background: #444;
}


.sidebar::-webkit-scrollbar {/*滚动条整体样式*/
  width: 2px;     /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.sidebar::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
  border-radius: 10px;
  background: #ddd;
  opacity: 1;
}

.sidebar::-webkit-scrollbar-track {/*滚动条里面轨道*/
  opacity: 0;
}
.login-alert{
  position: fixed;
  z-index: 999;
  top: -20%;
  left: 50%;
  display: flex;
  -ms-align-items: center;
  align-items: center;
  padding: 0px 20px;
  background-color: rgba(255,255,255,0.8);
  width: 300px;
  margin-left: -150px;
  border-radius: 4px;
  transition-duration: 0.3s;
}
.login-alert.act{
  top: 10px;
}
.login-alert span{
  font-size: 24px;
  color: #ff8100;
}
.login-alert p{
  line-height: 50px;
  margin-left: 10px;
  font-size: 16px;
  color: #ff8100;
}
.open-item .border-bottom{
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 0px!important;
}
.open-item .border-bottom span{
  transition-duration: 0.2s;
} 
.nav1 .open-item.open .border-bottom span{
  transform: rotate(180deg);
} 
.open-item+ul{
  display: none;
}
.open-item+ul>li a{
  padding-left: 17px!important;
}
.open-item.open+ul{
  display: block;
}
.sidebar .nav .nav-item.open-item{
  padding: 15px 1rem;
  
}
.open-item .border-bottom{
  display: flex;-ms-align-items: center;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sidebar .nav .nav-item.open-item:hover{
  background-color: #f4e9f9
}
.sidebar .nav .nav-item.open-item .text-secondary{
  color: #343434!important;
}
.sidebar .nav .nav-item.open-item .text-secondary i{
  font-style: normal;
}
.sidebar .nav .nav-item.open-item .text-secondary span{
  font-size: 20px;
}
.sidebar .nav .nav-item.open-item.open{
  background: linear-gradient(to right, rgb(100, 123, 234), rgb(154, 85, 255));
  border-bottom: 1px solid rgba(0,0,0,.09)
}
.sidebar .nav .nav-item.open-item.open .text-secondary,
.sidebar .nav .nav-item.open-item.open span{
  color: #fff!important;
}

.sidebar .nav.sub-menu{margin-left: 28px;margin-bottom: 0px;}
.sidebar .nav2 .nav-item.open-item .text-secondary{
  color: #444!important;
}
.sidebar .nav2 .nav-item.open-item span{
  color: #999;
}
.sidebar .nav a{
  text-decoration: none;
}
.page-warn div{
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
  margin-bottom: 1.5rem;
  background-color: #ffe3ba;
  border-radius: 4px;
  padding: 0px 15px;
  line-height: 43px;
}
.page-warn div p{
  font-size: 14px;
  color: #ff9800;
}
.page-warn div p span{
  margin-right: 6px;
  color: #ff9800!important;
}
.main-panel{
  min-height: 100vh
}
select.form-control {
  padding: .4375rem .35rem;
  border-radius: 4px;
  background-color: transparent;
     -webkit-border-radius: 4px;
     outline: none;
     border: 1px solid #dcdfe6;
}
input.form-control{
  padding-left: .35rem;
  border-radius: 4px;
}
.alert .v-calendar{border-radius: 0px;min-width: unset;}
.alert .v-calendar .input-field{border-radius: 0px;}
.alert .v-calendar .input-field input{height: 38px;padding-left: 36px;padding-right: 15px;border-radius: 0px;padding-right: 0px}
.sidebar .nav .nav-item.open-item{
  position: relative;
  z-index: 3;
}
.sidebar .nav input:checked+ .nav-item + ul{
  display:block
}
.sidebar .nav .nav-item.open-item + ul{
  background-color: #9a55ff;
  margin-top: -7px;
  padding-top: 9px;
}
.sidebar .nav .nav-item.open-item + ul .nav-item{
  padding: 0px 15px;
}
.sidebar .nav .nav-item.open-item + ul .nav-item .nav-link{
  padding-left: 0px!important;
  padding: 0.9rem 0px!important;
}
.sidebar .nav .nav-item.active{
  background-color: transparent;
}
.sidebar .nav.sub-menu{
  margin-left: 0px;
  padding-left: 10px;
}
.sidebar .nav.sub-menu .nav-item .nav-link:before{
  left: -20px;
}
.sidebar .nav .nav-item.open-item + ul .nav-item:hover,
.sidebar .nav .nav-item.open-item + ul .nav-item:hover a span,
.sidebar .nav .nav-item.open-item + ul .nav-item:hover a i{
  background-color: transparent;
  color: #fff!important
}
.form-input .v-calendar .input-field input{
  border-radius: 0px;
  height: 44px;
}
table{
  table-layout:fixed;
  word-break:break-all;
}
table thead{
  /*background-color: var(--color-active);*/

}
table thead p{
  line-height: 45px;
  font-weight: bold;
  color: #fff;
  
}
table p{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
table tbody tr:nth-child(2n - 1){
  /*background-color: #f6f6f6;*/
}
.code{
  position: relative;
  background-color: #f1f1f1;
  border-radius: 4px;
  height: 44px;
}
.code .b{
  content: '';
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #9a55ff;
}
.code span{
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 18px;
  color: #aaa;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: #fff;
  border: 1px solid #efefef;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(0,0,0,.1);
  cursor: pointer;
}

/*新添加*/
.sidebar{
  background: var(--color-theme);
}
.card-body .systemColor2{color: var(--color-theme)!important;}
.card-body .systemColor2 span{color: var(--color-theme)!important;}
.form-check .form-check-label input[type="radio"]:checked + .input-helper:before{background: var(--color-theme);}
.btn-gradient-primary,
.sidebar .nav .nav-item.open-item.open,
.sidebar .nav .nav-item.open-item:hover{
  background: var(--color-theme);
}
.sidebar .nav .nav-item.open-item.open{background: var(--color-active)}
.sidebar .nav .nav-item.open-item + ul {
  background: var(--color-active);
}
.sidebar .nav .nav-item:hover{background-color: transparent;}
.sidebar .nav .nav-item.open-item .text-secondary,
.open-item .border-bottom span{
  color: #e0e0e0!important
}
.sidebar .nav .nav-item .font-weight-bold{
    color: #fff;
}
.listSummary{
  border: 1px solid #ddd;
  padding: 26px 0px;
}
.listSummary ul{
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}
.listSummary ul li{
  flex-grow: 1;
  text-align: center;
  border-right: 1px solid #ddd
}
.listSummary ul li:last-child{
  border-right: 0px;
}
.listSummary .p1{
  color: #444;
  font-size: 14px;
}
.listSummary .p2{
  color: #444;
  font-size: 14px;
  margin-top: 4px;
}
.listSummary .p1 span{
  font-size: 24px;
  font-weight: bold;
  color: #444;
  margin-right: 4px;
}
.card .card-title{
  font-size: 16px!important;
}
.card .card-title span{
  margin-right: 6px;
}
.content-wrapper{
  padding: 1rem;
}
.card .card-body{
  padding: 1.5rem;
  padding-bottom: 1rem;
}
.grid-margin, .purchase-popup{
  margin-bottom: 1.5rem;
}
.ft {
  width: calc(100% - 270px)!important;
}
.btn-gradient-dark{
  background: #3e4b5b
}
.form-control,.btn{
  padding-top: 11px!important;
  padding-bottom: 11px!important;
}
.btn p{line-height: 16px}
.select-data input.form-control,
.select-data select.form-control,
.select-data .v-calendar .input-field{
  min-width: unset;
}
.select-data .v-calendar .input-field input {
    border-radius: 0.1875rem;
    border: 1px solid #dcdfe6;
    outline: none;
}
.select-data select.form-control{
  height: 38px!important
}
.col-md-3 {
  flex: 0 0 25%;
  max-width: calc(25% - 5px);
}
.sidebar .nav .nav-item .nav-link .menu-title,
.sidebar .nav .nav-item .nav-link i.menu-icon,
.sidebar .nav .nav-item.open-item + ul .nav-item .nav-link{
  color: #d0d0d0;
}
.sidebar .nav .nav-item.active > .nav-link .menu-title,
.sidebar .nav .nav-item.active > .nav-link i,
.sidebar .nav .nav-item.open-item + ul .nav-item .nav-link.active,
.sidebar .nav .nav-item.open-item + ul .nav-item .nav-link.active:before{
  color: #fff;
}
select.form-control:not([size]):not([multiple]) {
    height: 38px;
}
.wrapper{
  background: #d4d4d4;
}
.card .card-title{
  color: #ddd;
}
.card-title span{
  color: #999;
  cursor: pointer;
}
.row{
  margin-right: -10px;
  margin-left: -10px;
}
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
  padding-right: 10px;
  padding-left: 10px;
}
@media screen and (max-width: 960px) {
  .w-100{margin-right: 0px;margin-left: 0px;}
}
.ft{
  width:calc(100% - 270px)!important;
  bottom: 10px!important;
}
.ft{
    bottom: unset!important;
    top: 80px!important;
  }
  .ft .card-body{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
@media screen and (max-width: 1500px) {
  .sidebar{width: 220px;}
  .navbar.default-layout-navbar .navbar-brand-wrapper{width: 220px;}
  .navbar.default-layout-navbar .navbar-menu-wrapper,.main-panel{width:calc(100% - 220px);}
  .ft{
    width:calc(100% - 230px)!important;
    bottom: unset!important;
    top: 80px!important;
  }
  .ft .card-body{
    padding-left: 20px!important;
    padding-right: 20px!important;
  }
  .ft .right button:last-child{
    margin-right: 0px!important;
  }
}
@media screen and (max-width: 990px) {
  .main-panel{width: 100%}
}
.verify{
  display: flex;
  justify-content: space-between;
  -ms-align-items: center;
  align-items: center;
}
.verify .left{
  display: flex;
  -ms-align-items: center;
  align-items: center;
  width: calc(100% - 60px);
  background-color: #8bc34a;
  border-radius: 4px;
}
.verify .right{
  width: 40px;
  height: 100%;
  text-align: center;
}
.verify .left input{
  width: 40%;
  line-height: 48px;
  background-color: transparent;
  border: none;
  color: #fff;
}
.verify .left p{
  width: 50px;
  text-align: center;
  color: #fff;
}
.verify .left span{
  padding: 0px 10px;
  color: #fff;
}
.form-check-primary.form-check label input[type="checkbox"]:checked + .input-helper:before, 
.form-check-primary.form-check label input[type="radio"]:checked + .input-helper:before{
  background: var(--color-theme)
}
.chartGroup,
button.systemColor2,
.form-check .form-check-label input[type="checkbox"]:checked + .input-helper:before{
  background: var(--color-theme)!important
}
.grid-margin{margin-bottom: 0px;}
.form-group .v-calendar{min-width:unset;}
.selectData .v-calendar .input-field input,.form-group .v-calendar .input-field input{height: 38px;min-width:unset;}
.selectData{
  width:calc(100% + 10px);
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}
.selectData>div{
  display: flex;
  /*flex-grow: 1;*/
  -ms-align-items: center;
  align-items: center;
  margin: 0px 5px;
  margin-bottom: 10px;
  /*width: calc(11.66% - 10px);*/
  width: auto!important;
}
.selectData>div:last-child{
  flex-grow: 1;
}
.selectData div>p{
  white-space: nowrap;
  margin-right: 15px;
}
.selectData .v-calendar .input-field input{
  border-radius: 4px;
}
.selectData button p{
  color: #fff;
}
.selectData button svg{
  fill: #fff;
  width:12px;
  height: 12px;
}
.tableWait{
  width:100%;
  height:100%;
  border-radius: 4px;
  background-color: rgba(255,255,255,1);
  position: absolute;
  left: 0%;
  top: 0%;
  /*margin-left: -60px;
  margin-top: -60px;*/
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
}
.tableWait svg{
  width:40px;
  height:40px;
  opacity: 0.8;
}
.tableWait svg path{
  fill: #444
}
.btn-gradient-primary:not([disabled]):not(.disabled):active, 
.btn-gradient-primary:not([disabled]):not(.disabled).active, 
.show > .btn-gradient-primary.dropdown-toggle{
  background: transparent;
}
.searchClassify .classify2>li:after{
  top: 18px!important;
}
.selectData div a.btn{
  padding-top: 10px!important;
  padding-bottom: 10px!important;
  border: 1px solid var(--color-theme)!important;
  background-color: #fff;
  
}
.selectData div a.btn p{
  color: var(--color-theme)!important;
}
.selectData .v-calendar{width:100%;min-width: unset;}
.selectData .v-calendar .input-field svg.datepicker{width: 16px;height: 16px;}
.sidebar .nav{margin-bottom: 0px;}
.wrapper{background: var(--color-theme)}
table td,table th{border: 0px!important;}
table{border: 0px!important;}
table tr{border-bottom: 1px solid #ebeef5}
.card table tbody tr:hover{
  background-color: #f5f7fa
}
table thead p{color: #909399;line-height: 50px;}
table tbody p{color: #747474;line-height: 48px;}
@media screen and (max-width: 900px) {
  .navbar.default-layout-navbar .navbar-brand-wrapper{width: 55px;}
  .navbar.default-layout-navbar .navbar-menu-wrapper, .main-panel{width: auto!important;}
  .pageBox,.pcNav{display:none!important}
  .card{height:auto!important;}
  .table-responsive{height:calc(100vh - 100px)!important;}
  .card-body>table{display:none;}
  .ft{width:calc(100% - 13px)!important;}
  .ft .stretch-card{padding-bottom: 0px!important;}
  .selectData>div{width: 100%}
}
.status span{
  border-radius: 2px;
  line-height: 24px;
  font-size: 12px;
  background-color: #999;
  padding: 0px 10px;
  display: inline-block;
  color: #fff;
}
.RadioBox{
  display: flex;
  -ms-align-items: center;
  align-items: center;
  -ms-align-items: center;
  align-items: center;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  overflow: hidden;
}
.RadioBox input{
  position: absolute;
  top: 0px;
  opacity: 0;
}
.RadioBox label{
  white-space: nowrap;
  margin-bottom: 0px;
}
.RadioBox label span{
  display: inline-block;
  line-height: 38px;
  padding: 0px 15px;
  font-size: 12px;
  border-right: 1px solid #dfdfdf;
}
.RadioBox label:last-child span{border-right: none;}
.RadioBox label input:checked~span{
  color: #fff;
  background-color: var(--color-theme);
}
.selectData2 .v-calendar .input-field{
  min-width: unset;
}
.selectData2 .v-calendar .input-field input{padding-right: 0px;}
.el-date-editor.el-input,.el-input--suffix,.el-date-editor.el-input__wrapper{height: 38px;line-height: 38px;width: 100%}
.type-list .el-input--suffix{height:30px;line-height: 30px}
.el-select--large{line-height: 38px;}
.el-select{width: 100%}
.tableWait{
  z-index: -2;
  opacity: 0;
  transition-duration: 0.3s;
}
.tableWait.showWait{
  z-index: 1;
  opacity: 1;
}
.el-table .cell{line-height: 50px;white-space:nowrap;}
.el-table th.el-table__cell.is-leaf{border-bottom: var(--el-table-border)!important}
.alert .el-table .cell{line-height: 38px;}
.alert .el-table__empty-block{line-height: 40px;min-height: 40px;}
.alert .el-table__empty-text{line-height: 40px;}
.el-table__inner-wrapper .el-input--suffix{line-height: 28px;min-height: 28px;height: 28px;}
.el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
  border-bottom: var(--el-table-border)!important;
}
.el-link{font-size: 12px;}
thead .el-checkbox{margin-left: 11px;}
.el-checkbox{margin-bottom: 0px;}
.el-button--primary.is-link span, .el-button--primary.is-plain span, .el-button--primary.is-text span,
.el-dropdown{
  color: #409eff
}
.el-button--primary.is-link:hover span, .el-button--primary.is-plain:hover span, .el-button--primary.is-text:hover span{
  color: #79bbff
}
.goodsmk .el-input--suffix.el-input{
  height: 38px;
  line-height: 38px;
}
.product-data .el-date-editor.el-input,.product-data .el-input--suffix,.product-data .el-date-editor.el-input__wrapper{height: 30px;line-height: 30px;width: 100%}
.alertSelectD .el-input--suffix.el-input{
  height: 32px;
  line-height: 32px;
}
.alert-xlget .el-button{
  height:28px;
  line-height: 28px;
}
.stockallBtn button{
  height:36px;
  line-height: 36px;
}
:focus{
  outline: none;
}
.alert-addAccount .form-xq .form-check .form-check-label input[type="checkbox"] + .input-helper[data-v-f93d0a34]:before,
.alert-addAccount .form-xq .form-check .form-check-label input[type="checkbox"] + .input-helper[data-v-f93d0a34]:after{
  transform: scale(0.8);
}
.billingTable .el-select-dropdown__item{
  padding-left: 10px;
  padding-right: 10px;
}
.setting{display: none;}
.btn {
    padding: 0.875rem 1rem;
  }
  .card{box-shadow: 0px 0px 0px rgba(0,0,0,.06)}
  .el-tree-node__content{height:36px;padding-left: 0px!important;}
  .tree .el-tree-node {
    position: relative;

  }

  .tree .el-tree-node__children {
    padding-left: 16px;
  }

  .tree .el-tree-node :last-child:before {
    height: 38px;
  }

  .tree .el-tree > .el-tree-node:before {
    border-left: none;
  }
  .tree-container .el-tree > .el-tree-node:after {
    border-top: none;
  }

  .tree .el-tree-node__children .el-tree-node:before {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }

  .tree .el-tree-node:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }

  .tree .el-tree-node:before {
    border-left: 1px dashed #dddddd;
    bottom: 0px;
    height: 100%;
    top: -26px;
    width: 1px;
  }

  .tree .el-tree-node:after {
    border-top: 1px dashed #dddddd;
    height: 20px;
    top: 16px;
    width: 10px;
  }
  .tree .custom-tree-node {
    padding-left: 10px;
    display: flex;
    -ms-align-items: center;
    align-items: center;
  }
  .tree>.el-tree-node:after{
    border-top: none;
  }
  .el-tree-node__content>.custom-tree-node .icon1{
    display: block;
  }
  .is-expanded>.el-tree-node__content>.custom-tree-node .icon1{
    display: none;
  }
  .is-expanded>.el-tree-node__content>.custom-tree-node .icon2{
    display: block;
  }

  .el-tree-node__expand-icon.is-leaf{color: var(--el-tree-expand-icon-color);}
  .filter-tree span{
    line-height: 24px;
    padding: 0px 4px;
    border-radius: 4px;
    display: block;
    font-size: 12px;
    margin-left: 5px;
  }
  .filter-tree span.active{
    color: #fff;
    background-color: var(--color-active);
  }
  .card-body .el-textarea__inner{min-height: 38px!important}
  div,span,a,p,li{
    user-select:none;
    -ms-user-select:none;
    -moz-user-select:none;
    -khtml-user-select:none;
    -webkit-user-select:none;
  }
  .goodsmk .el-button{height: 38px;}
  .el-input-number{width:auto;}
  .table-el .el-input--suffix{height: 30px;width: 140px;}
	
	.noneZhouTable{
	  display: none!important;
	}
	.noneZhouTr{
	  display: none!important;
	}
	.noneZhouTd{
    display: none!important
	}
	.noneZhou{
	  display: none!important;
	}
	.noneZhouFl{
		display: none!important;
	}
	.noneZhouF{
		display: none!important;
		/* display: list-item!important; */
	}
  /*周总*/
  .zhouTable{
		display: table!important
  }
  .zhouTr{
		display: table-row!important;
  }
  .zhouTd{
		display: table-cell!important;
  }
  .zhou{
	  display:block!important;
  }
  .ZhouFl{
		display: flex!important;
  }
  .cell{overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;}
  .cell>div{
    overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  }
  .nav input[type="radio"]{
    position:absolute;
    opacity: 0;
  }
</style>
